import React from 'react';
import styled from 'styled-components';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // this.setState({ hasError: false });
      // error logging when implemented
      this.props.setView('error')
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <Container>
          </Container>
        )
      }
      return this.props.children;
    }
  }

export default ErrorBoundary;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`
