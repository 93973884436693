import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';
import pentadataLogo from '../images/pentadata_logo_170.png'

const NoPage = () => {
  return (
    <Container>
        <Content>
            <LogoTitle src={pentadataLogo} />
            <TitleText>Oops. Page not found.</TitleText>
            <Text>Please navigate back to <ReactLink to='/'><Span>Pentadata</Span></ReactLink></Text>
        </Content>
    </Container>
  );
}

export default NoPage;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 28rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1.8em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const Text = styled.div`
  width: 100%;
  font-size: 1.4em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text };
  margin-bottom: 1rem;
`

const Span = styled.span`
  color: ${({ theme }) => theme.colors.primary };
  cursor: pointer;
`

const LogoTitle = styled.img`
  max-width: 8rem;
  height: auto;
  width: auto;
  margin: auto;
`