import React, { useState, useCallback } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { setToken } from '../services/login';
import styled from 'styled-components';
import Toggle from './common/ToggleSwitch';
import pentadataLogo from '../images/pentadata_logo_170.png';
import Modal from 'react-modal';
import { modalStyles } from '../Theme';

const Login = ({ 
  tokenState, handleLogout, setMessage, message, getToken, setUserType, environment, 
  setEnvironment, setAlert, login, mfaLogin, setSlackMsg
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [modalMessage, setModalMessage] = useState('');
  const [OTP, setOTP] = useState('');
  const [signature, setSignature] = useState('');
  const [openModalMFA, setOpenModalMFA] = useState(false);
  const USER_TYPES = ['subscriber']

  const handleMFASubmit = useCallback(async(e) => {
    e.preventDefault();
    setModalMessage('');
    if (!OTP) {
      setModalMessage('OTP required');
      return;
    }
    if (OTP.length !== 6) {
      setModalMessage('Check OTP');
      return;
    }
    try {
      const { token, refresh_token, userType, status } = await mfaLogin({ signature, otp: OTP }, );
      switch(status){
        case 200:
          if (USER_TYPES.includes(userType)) {
            setToken({ token, refresh_token} );
            setModalMessage('');
            setUserType(userType);
            getToken();
          } // May need to add redirect for other user types
          break;
        case 400:
          setModalMessage('There was a problem with the request, please contact Bitdoor to resolve');
          break;
        case 403:
          setModalMessage('Max attempts reached, please wait 10 minutes and then try to login again.');
          break;
        case 409:
          setModalMessage('Incorrect OTP, please re-enter the code sent to you.');
          break;
        default:
          setModalMessage('There was a problem with the request, please contact Bitdoor to resolve');
      }
    } catch (err) {
      setAlert({ type: 1 });
      console.log(err);
    }
  }, [signature, getToken, setModalMessage, setUserType, OTP, USER_TYPES, mfaLogin, setAlert])

  const handleSubmit = useCallback(async(e) => {
    setMessage('');
    e.preventDefault();
    const creds = { email, password };
    try {
      const { token, refresh_token, message, signature, userType, status } = await login(creds);
      switch(status) {
        case 200:
          if (userType === 'subscriber') {
            setToken({ token, refresh_token });
            setUserType(userType);
            getToken();
            setMessage('');
          }
          if (userType === 'consumer') {
            setAlert({ type: 0, loginConsumer: true });
            setMessage('Wrong account type, try logging in using link below.')
          }
          break;

        case 202:
          setSignature(signature);
          setOpenModalMFA(true);
          break;

        case 429:
          setMessage(`
            This account was locked for security reasons. It will be unlocked automatically after a security check period. 
            Contact us immediately at support@pentadatainc.com to unlock it.
          `)
          setSlackMsg('I am having trouble logging into my account.');
          setAlert({ type: 0 , slack: true, email });
          break;

        default:
          if (message) {
            setMessage(message);
            setAlert({ type: 0 });
          }
      }
    } catch {
      setAlert({ type: 1 })
    }
  }, [email, password, setMessage, getToken, setUserType, setAlert, setSignature, login, setSlackMsg])

 const signUpUrl = () => { window.open('https://www.pentadatainc.com', '_blank') }

 const handleToggle = useCallback(() => {
  if (environment) {
    setEnvironment(0)
  } else {
    setEnvironment(1)
  }
 }, [environment, setEnvironment])

 const showMFA = openModalMFA
  ? <Modal
    isOpen={openModalMFA}
    style={modalStyles}
    contentLabel="Form"
    overlayClassName="modal-overlay"
  >
    <ModalContent style={{ width: '24rem' }}>
      <Text style={{ fontSize: '1.2em', margin: 'auto auto 1rem auto' }}><strong>Multi Factor Authentication</strong></Text>
      <Text style={{ margin: 'auto auto 1rem auto' }}>You will receive a OTP through the method that was set up.</Text>
      <Label>Enter One Time Password: </Label>
      <MFARow style={{ margin: '1rem 0 0 0' }}>
        <Input
          value={OTP}
          name='OTP'
          autoComplete='off'
          type='number'
          style={{ width: '6rem', marginBottom: '0', textAlign: 'center' }}
          onChange={(e) => setOTP(e.target.value)}
        />
      </MFARow>
      <Message style={{ height: '1rem' }}>{modalMessage ? modalMessage : ''}</Message>
      <Row>
        <Button onClick={() => { setOpenModalMFA(false) }} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
        <Button onClick={handleMFASubmit} style={{ width: '7rem' }}>Submit</Button>
      </Row>
    </ModalContent>
  </Modal>
  : <></>

 const loginOrRefrsh = tokenState === 'expired'
  ? <RefreshAlert>
      <RefreshText>Session has expired. Please log in with credentials.</RefreshText>
      <Row>
        <RefreshButton onClick={handleLogout}>
          OK
        </RefreshButton>
      </Row>
    </RefreshAlert>
  : <LoginForm>
      <LogoTitle src={pentadataLogo} />
      <TitleText>DASHBOARD LOG IN</TitleText>
      <Form>
        <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='current-email' />
        <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='current-password' />
        <ForgotLink><ReactLink to='/set-pw' className='link'>Forgot password?</ReactLink></ForgotLink>
        <Button onClick={handleSubmit}>{environment === 0 ? 'Sandbox Sign In' : 'Production Sign In'}</Button>
      </Form>
      {/* <Message>{message}</Message> */}
      <Row style={{margin: '2rem .5rem .5rem .5rem'}}>
        <Text>{environment === 0 ? 'Sandbox' : 'Production'} </Text>
        <Toggle 
          id={`toggle-environment`}
          isOn={environment} 
          handleToggle={handleToggle}
        />
      </Row>
      <SignUpLink>Not yet a member? <span onClick={signUpUrl} className='link'>Sign Up</span></SignUpLink>
    </LoginForm>

  return (
    <Container>
      {loginOrRefrsh}
      {showMFA}
    </Container>
  );
}

export default Login;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 1.5rem;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.pentaPrimary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const ForgotLink = styled.div`
  text-align: left;
`

const SignUpLink = styled.div`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-top: 3rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  height: 1rem;
  font-size: .9em;
  color: red;
  margin: .5rem;
`

const RefreshAlert = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2rem;
`

const RefreshText = styled.div`
  width: 100%;
  font-size: 1.1em;
  font-weight: 400;
  color: #000;
  margin-bottom: 1rem;
`

const RefreshButton = styled.button`
  width: 3.6rem;
  font-weight: 500;
  margin: .4rem 1rem 0 1rem;
  font-size: 1em;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  height: 1.8rem;
  text-align: center;
  padding: 0.3rem .4rem;
  letter-spacing: 0.0875rem;
`

const Text = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.text };
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`

const MFARow = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  align-items: center;
  margin: 0;
  height: 3rem;
  input {
    height: 1.5rem;
    width: 8rem;
  }
`

const Label = styled.label`
  font-size: 1em;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  text-align: center;
`