import React, { useContext } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Modal from 'react-modal';
import { AppContext } from '../../App';
import { formatToLocal } from '../../services/utils';

Modal.setAppElement('#root')

const DataUsage = () => {
  const { dashboardData } = useContext(AppContext)
  const { previous, current } = dashboardData.products

  return (
    <Container>
      <SideNav />
      <Content>
        <Card>
          <CardTitle>
            <Icon><i className="fas fa-cloud-download-alt"></i></Icon>
            Data Usage
          </CardTitle>
          <Subtitle>Last updated: {formatToLocal(current.updated_at)} local time</Subtitle>
          <CardText>
            These values are aggregated and updated several times through the day.<br/>
            For real-time information please contact us.
          </CardText>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <TableHeader colSpan='4'>Transactionz</TableHeader>
                </tr>
                <tr>
                  <StyledTh></StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Previously Added</StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Added This Month</StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Deleted This Month</StyledTh>
                </tr>
              </thead>
              <tbody>
                  <StyledTr>
                    <td>Account</td>
                    <StyledTd>{previous.bank_account}</StyledTd>
                    <StyledTd>{current.bank_account}</StyledTd>
                    <StyledTd>{current.bank_account_delete}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Bank-login</td>
                    <StyledTd>{previous.bank_login}</StyledTd>
                    <StyledTd>{current.bank_login}</StyledTd>
                    <StyledTd>{current.bank_login_delete}</StyledTd>
                  </StyledTr>
              </tbody>
            </table>
          </TableContainer>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <TableHeader colSpan='2'>Banking APIs</TableHeader>
                </tr>
                <tr>
                  <StyledTh style={{width: '30%'}}></StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Calls This Month</StyledTh>
                </tr>
              </thead>
              <tbody>
                  <StyledTr>
                    <td>ACH</td>
                    <StyledTd>{current.bank_account_ach}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Balance</td>
                    <StyledTd>{current.bank_account_balance}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Balance Live</td>
                    <StyledTd>{current.bank_account_balance_live}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Statement</td>
                    <StyledTd>{current.bank_account_statement}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Owner</td>
                    <StyledTd>{current.bank_account_owner}</StyledTd>
                  </StyledTr>
              </tbody>
            </table>
          </TableContainer>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <TableHeader colSpan='2'>Machine Learning Products</TableHeader>
                </tr>
                <tr>
                  <StyledTh style={{width: '30%'}}></StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Calls This Month</StyledTh>
                </tr>
              </thead>
              <tbody>
                  <StyledTr>
                    <td>MerchantSight</td>
                    <StyledTd>{current.merchant_sight}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>MccMatch</td>
                    <StyledTd>{current.mcc_match}</StyledTd>
                  </StyledTr>
              </tbody>
            </table>
          </TableContainer>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <TableHeader colSpan='2'>UGetMe</TableHeader>
                </tr>
                <tr>
                  <StyledTh style={{width: '30%'}}></StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Calls This Month</StyledTh>
                </tr>
              </thead>
              <tbody>
                  <StyledTr>
                    <td>Authentication</td>
                    <StyledTd>{current.auth}</StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <td>Credit Report</td>
                    <StyledTd>{current.credit_report}</StyledTd>
                  </StyledTr>
              </tbody>
            </table>
          </TableContainer>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <TableHeader colSpan='4'>Person</TableHeader>
                </tr>
                <tr>
                  <StyledTh></StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Previously Added</StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Added This Month</StyledTh>
                  <StyledTh style={{textAlign: 'center'}}>Deleted This Month</StyledTh>
                </tr>
              </thead>
              <tbody>
                  <StyledTr>
                    <td>Persons</td>
                    <StyledTd>{previous.person}</StyledTd>
                    <StyledTd>{current.person}</StyledTd>
                    <StyledTd>{current.person_delete}</StyledTd>
                  </StyledTr>
              </tbody>
            </table>
          </TableContainer>
        </Card>
      </Content>
    </Container>
  );
}

export default DataUsage;

const Container = styled.div`
  display: flex;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: baseline;
  padding: .5rem auto;
`

const Subtitle = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.toggleOff };
  margin: .5rem;
`

const CardText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.2em;
  font-weight: 500;
  padding: .5rem;
  margin-bottom: 1rem;
`

const TableContainer = styled.div`
  margin-top: 1rem;
  min-width: 45rem;
  max-width: 75%;
  table {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    border-radius: .25rem;
    text-transform: none;
  }
`

const TableHeader = styled.td`
  color: #fff;
  font-weight: 500;
  font-size: 1.2em;
  background-color: ${({ theme }) => theme.colors.primary };
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  padding: .5rem .5rem .5rem 1rem;
`

const StyledTd = styled.td`
  text-align: center;
  margin: auto;
`

const StyledTh = styled.th`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 400;
`

const StyledTr = styled.tr`
  td {
    color: ${({ theme }) => theme.colors.textSecondary }
  }
`
