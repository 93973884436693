import React from 'react';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { formatDateSlash } from '../../services/utils';

const Cards = ({ personCards }) => {

  const SearchBar = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (
      <div>
        <input
          style={{height: '1.6rem', marginRight: '1rem'}}
          ref={ n => input = n }
          type="text"
          id='cards-search'
          onChange={handleClick}
          placeholder='Search'
        />
      </div>
    );
  };
  
  const DateFormatter = (cell, row) => {
    return (
      <LongText>{formatDateSlash(cell)}</LongText>
    );
  };

  const LongFormatter = (cell, row) => {
    return (
      <LongText>{cell}</LongText>
    );
  };

  const ExpryFormatter = (cell, row) => {
    return (
      <LongText>{`${row.exp_year}/${cell}`}</LongText>
    )
  }

  const columns = [{
    dataField: 'card_id',
    text: 'Card ID',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
      else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'last_four',
    text: 'Last 4',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'exp_year',
    text: 'Expiration',
    sort: true,
    formatter: ExpryFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'postal_code',
    text: 'Postal Code',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  },
  {
    dataField: 'consent',
    text: 'Consent',
    sort: true,
    formatter: LongFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  },
  {
    dataField: 'last_opt_in',
    text: 'Opted In',
    sort: true,
    formatter: DateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }];

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true
  // };

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { size } entries
    </DataTableText>
  );

  const options = {
    custom: true,
    totalSize: personCards ? personCards.length : 0,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
    // showTotal: true,
  };

  const showTable = () => {
    if(personCards) {
      if(!personCards.length) {
        return(
          <StandAloneContainer style={{alignItems: 'flex-start'}}>
            <NoDataText>
              No accounts enrolled for Person.
            </NoDataText>
          </StandAloneContainer>
        )
      }
      return(
        <TableContainer>
        <PaginationProvider
          pagination={ paginationFactory(options) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <div>
                <ToolkitProvider
                  keyField={"card_id"}
                  data={ personCards }
                  columns={ columns }
                  exportCSV={ { exportAll: true } }
                  search
                >
                  {
                    props => (
                      <DataTableContainer>
                        <Row style={{height: '4rem', justifyContent: 'flex-end'}}>
                          <StandAloneContainer style={{width: '100%'}}>
                            <SearchBar { ...props.searchProps } />
                            <Row style={{justifyContent: 'flex-end'}}>
                              <SizePerPageDropdownStandalone
                                { ...paginationProps }
                              />
                              <DataTableText>
                                items/page
                              </DataTableText>
                            </Row>
                          </StandAloneContainer>
                        </Row>
                        <BootstrapTable
                          { ...props.baseProps }
                          // selectRow={ selectRow }
                          { ...paginationTableProps }
                          striped
                          hover
                        />
                        <Row>
                          <PaginationTotalStandalone
                            { ...paginationProps }
                          />
                          <PaginationListStandalone
                            { ...paginationProps }
                          />
                        </Row>
                        {/* <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } /> */}
                      </DataTableContainer>
                    )
                  }
                </ToolkitProvider>
              </div>
            )
          }
        </PaginationProvider>
        </TableContainer>
      )
    } else {
      return(
          <StandAloneContainer style={{alignItems: 'flex-start'}}>
            <NoDataText>
              There was a problem loading cards.
            </NoDataText>
          </StandAloneContainer>
        )
    }
  }

  return (
    <Container>
      <Content>
        <Row style={{height: '4rem', justifyContent: 'flex-start', marginTop: '1rem'}}>
          <CardTitle><Icon><i className="fas fa-credit-card"></i></Icon>Cards</CardTitle>
        </Row>
        {showTable()}
      </Content>
    </Container>
  );
}

export default Cards;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const TableContainer = styled.div`
  font-size: .9em;
  margin: 0;
  padding: 0;
`

const DataTableContainer = styled.div`
  table td {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6rem;
    vertical-align: middle;
    @media (max-width: 768px) {
      max-width: 4rem;
    }
  }
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const LongText = styled.div`
  text-overflow: ellipsis;
  max-width: 6rem;
  text-transform: none;
`

const NoDataText = styled.div`
  font-size: 1.4em;
  margin: 2rem;
`