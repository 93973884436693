import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ContactForm from '../ContactForm';
import Accordion from '../common/Accordion';

const Faqs = ({ view, handleView }) => {

  const questions = [
    {q: "What do you need my personal information for?", a: "We collect personal information at this website in order to verify your identify when you visit our website to protect you from fraud, unauthorized access, or identify theft."},
    {q: "What kind of information is in my shared data?", a: "The information in your shared data can be categorized into 4 different categories that are listed and defined below: </br> <ul><li><strong>Identifiers</strong> Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, IP address, email address, account number</li><li><strong>Personal Characteristics</strong> – Bank account number, credit card number, debit card number</li><li><strong>Commercial Information</strong> – Purchasing or consuming histories or tendencies</li><li><strong>Geolocation Data</strong> – Purchase location information</li></ul>"},
    {q: "Where do you get this data from?", a: "We collect data from financial institutions, credit bureaus, payment companies, processing companies, and other sources. We do not collect your data unless you have opted-in from the companies or on our website."},
    {q: "How can I Opt Out of my data being used?", a: 'You have this option on our user dashboard. All you have to do is click “Opt-Out” next to the companies that are listed in your Opt In list.'},
    {q: "How is my data being used?", a: 'You are able to see “Who’s Tracking You” on our user dashboard. You will be able to see which companies are currently using your data to provide discounts for their services. You are able to download your data as long as you are opted-in to their program.'},
    {q: "Where can I learn more about your privacy policy?", a: "You can find our privacy policy here: <a href='https://pentadatainc.com/privacy-policy/' target='_blank'>https://pentadatainc.com/privacy-policy/</a>"},
  ]

  const collapseQuestions = questions.map((question, idx) => (
    <Accordion key={`q-faqs-${idx}`} title={question.q} text={question.a} />
  ))

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <CardTitle><Icon><i className="fas fa-question-circle"></i></Icon>FAQs</CardTitle>
          <CardText>
            Below you will find answers to some of the most frequently asked questions. If you have a 
            question that's not addressed, please use the form at the bottom of the page to ask us directly.
          </CardText>
          <AccordionContainer>
            {collapseQuestions}
          </AccordionContainer>
          <ContactForm description="Not finding the answer you need? Please use the form below to ask for help." />
        </Card>
      </Content>
    </Container>
  );
}

export default Faqs;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const AccordionContainer = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin-bottom: 3rem;
  vertical-align: middle;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
  margin-bottom: 2rem;
`