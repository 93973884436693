import React from 'react';
import styled from 'styled-components';

const ExportButton = ({ onExport, text, style }) => {
  const handleClick = () => {
    onExport();
  };

  return (
    <>
      <Button style={style} onClick={ handleClick }>{text}</Button>
    </>
  );

}

export default ExportButton;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  height: 2.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }

`