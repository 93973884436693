import React from 'react';
import Overview from './Overview';
import DataUsage from './DataUsage';
import YourApis from './YourApis';
import UseCases from './UseCases';
import DataSources from './DataSources';
import Support from './Support';
import Profile from './Profile';
import Persons from './Persons';
import AccountsCards from './AccountsCards';
import Merchant from './Merchant'
import Sight from './Sight'
import RequestProd from './RequestProd';
import Billing from './Billing';
import Pay from './Pay';
import ErrorPage from '../common/ErrorPage';

const SubscriberDashboard = ({ 
  view, dashboardData, handleView, webToken, fetchSubscriberData, fetchPersonAccounts,
  environment, personData, personCards, personAccounts, getToken, setPersonCards, setAlert,
  setPersonAccounts, accountSearch, setAccountSearch, merchantSearch, setMerchantSearch,
  setMessage, message, fetchDashboardData, deleteAccount, handleLogout, currentPerson,
  requestProduction, setCurrentPerson
}) => {

  switch(view) {

    case 'overview':
      return(
        <Overview />
      )

    case 'data usage':
      return(
        <DataUsage />
      )

    case 'your apis':
      return(
        <YourApis 
          view={view} 
          handleView={handleView} 
          dashboardData={dashboardData}
          fetchSubscriberData={fetchSubscriberData}
          webToken={webToken}
          environment={environment}
        />
      )

    case 'use cases':
      return(
        <UseCases 
          view={view} 
          handleView={handleView} 
          dashboardData={dashboardData}
        />
      )

    case 'data sources':
      return(
        <DataSources 
          view={view} 
          handleView={handleView} 
          dashboardData={dashboardData}
          fetchSubscriberData={fetchSubscriberData}
          webToken={webToken}
          environment={environment}
        />
      )

    case 'persons':
      return(
        <Persons />
      )

    case 'accounts':
      return(
        <AccountsCards
          view={view} 
          handleView={handleView} 
          dashboardData={dashboardData}
          webToken={webToken}
          environment={environment}
          personData={personData}
          personAccounts={personAccounts}
          personCards={personCards}
          fetchPersonAccounts={fetchPersonAccounts}
          getToken={getToken}
          setPersonCards={setPersonCards}
          setPersonAccounts={setPersonAccounts}
          accountSearch={accountSearch}
          setAccountSearch={setAccountSearch}
          currentPerson={currentPerson}
          setCurrentPerson={setCurrentPerson}
        />
      )

    case 'merchant':
      return(
        <Merchant
          view={view} 
          handleView={handleView} 
          environment={environment}
          getToken={getToken}
          merchantSearch={merchantSearch}
          setMerchantSearch={setMerchantSearch}
        />
      )
    
    case 'sight':
      return <Sight />

    case 'support':
      return(
        <Support 
          view={view} 
          handleView={handleView}
          getToken={getToken}
          setAlert={setAlert}
          environment={environment}
          dashboardData={dashboardData}
        />
      )

    case 'request prod':
      return(
        <RequestProd 
          view={view} 
          handleView={handleView}
          requestProduction={requestProduction}
        />
      )

    case 'billing':
      return(
        <Billing 
          view={view} 
          handleView={handleView}
          dashboardData={dashboardData}
          setMessage={setMessage}
          message={message}
          getToken={getToken}
          setAlert={setAlert}
          fetchSubscriberData={fetchSubscriberData}
        />
      )
    case 'subscriber profile':
      return(
        <Profile 
          view={view} 
          handleView={handleView} 
          dashboardData={dashboardData} 
          setMessage={setMessage}
          message={message}
          getToken={getToken}
          fetchDashboardData={fetchDashboardData}
          deleteAccount={deleteAccount}
          handleLogout={handleLogout}
          setAlert={setAlert}
          environment={environment}
        />
      )

      case 'pay':
        return(
          <Pay
            view={view}
            handleView={handleView}
            getToken={getToken}
            environment={environment}
            setAlert={setAlert}
          />
        )

      case 'error':
        return <ErrorPage />
    
    default: return null
  }

}

export default SubscriberDashboard;
