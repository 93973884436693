import React from 'react';
import styled from 'styled-components';
import SideNav from '../subscriber/SideNav';

const RequestProd = ({ view, handleView, requestProduction }) => {
  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <CardTitle><Icon><i className="fas fa-play-circle"></i></Icon>Request Production Access</CardTitle>
          <CardText>
            Are you ready to use real, production data for your app? Click the button below and we will reach to you right away.
          </CardText>
          <Row>
            <Button onClick={requestProduction}>Request Access</Button>
          </Row>
        </Card>
      </Content>
    </Container>
  );
}

export default RequestProd;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  height: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
`

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2rem;
  min-width: 5rem;
  font-size: 1em;
  margin: 1rem 0 0 0;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  };

`