import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SideNav from '../subscriber/SideNav';
import Loading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Desktop, Mobile, modalStyles, selectStyles } from '../../Theme';
import Toggle from '../common/ToggleSwitch';
import PhoneInput from 'react-phone-number-input'
import Modal from 'react-modal';
import Select from 'react-select';
import { getBaseURL } from '../../services/utils'
import isLoading from '../common/isLoading';
import 'react-phone-number-input/style.css'

const Profile = ({ 
  view, handleView, dashboardData, setMessage, message, getToken, setLoading,
  fetchDashboardData, setMessageModal, deleteAccount, handleLogout, setAlert,
  environment
}) => {
  const [showKey, setShowKey] = useState(false);
  const [showAlertToken, setShowAlertToken] = useState(false)
  const [MFAEnable, setMFAEnable] = useState(false);
  const [MFADisable, setMFADisable] = useState(false);
  const [openModalDeleted, setOpenModalDeleted] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [MFAVerify, setMFAVerify] = useState(false);
  const [OTP, setOTP] = useState('');
  const [MFA, setMFA] = useState({ value: 'email', label: 'Email' });
  const [MFAPhone, setMFAPhone] = useState('');

  const mfaOptions = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'Text' },
    { value: 'voice', label: 'Voice' }
  ]

  const disableMFA = useCallback(async () => {
    setLoading(true);
    try {
      const { token, tokenStatus } = await getToken();
      if (tokenStatus !== 'valid') return null;
      const res = await fetch(`${getBaseURL(environment)}/mfa`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        })
      })
      if (res.status === 200) {
        fetchDashboardData({ token });
      } else {
        setMessage('There was a problem turning off MFA');
        setMessageModal(true);
      }
    }
    catch (e) {
      setAlert({ type: 1 });
      console.log(e)
    }
    finally {
      setLoading(false);
    }
  }, [getToken, setAlert, setMessageModal, setMessage, fetchDashboardData, setLoading, environment])

  const handleDelete = useCallback(async() => {
    setLoading(true);
    const { status } = await deleteAccount();
    if (status === 200) {
      setLoading(false);
      setOpenModalConfirm(false);
      setOpenModalDeleted(true);
    }
  }, [deleteAccount, setLoading])

  const handleToggle = useCallback(() => {
    setMessage('')
    dashboardData.mfa_enabled ? setMFADisable(true) : setMFAEnable(true);
  }, [setMessage, dashboardData])

  const verifyMFA = useCallback(async () => {
    setLoading(true);
    setMessage('');
    if (!OTP) {
      setMessage('OTP required');
      setLoading(false);
      return;
    }
    try {
      const { token, tokenStatus } = await getToken();
      if (tokenStatus !== 'valid') return null;

      const res = await fetch(`${getBaseURL(environment)}/mfa-check`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          mfa_channel: MFA.value,
          mfa_channel_value: MFA.value === 'email' ? dashboardData.email : MFAPhone,
          otp: OTP
        })
      })
      switch (res.status) {
        case 200:
          setMFAVerify(false);
          setMFAEnable(false);
          fetchDashboardData();
          break;
        case 400:
          setMessage('There was a problem with the request, please contact Bitdoor to resolve.')
          setMessageModal(true);
          break;
        case 403:
          setMessage('Max attempts reached, please wait 10 minutes and then try to verify again.')
          setMessageModal(true);
          setMFAVerify(false);
          setMFAEnable(false);
          break;
        case 409:
          setMessage('Incorrect OTP, please re-enter the code sent to you.')
          setMessageModal(true);
          break;
        default:
          setMessage('There was a problem with the request, please contact Bitdoor to resolve.')
          setMessageModal(true);
      }
      setLoading(false);
    }
    catch (e) {
      setAlert({ type: 1 });
      setLoading(false);
      console.log(e)
    }
  }, [getToken, setAlert, MFA, MFAPhone, dashboardData, OTP, environment,
    fetchDashboardData, setMessage, setMessageModal, setLoading])

  const initMFA = useCallback(async () => {
    setLoading(true);
    setMessage('');
    if (MFA.value !== 'email' && !MFAPhone) {
      setMessage('Phone number required.');
      setLoading(false);
      // setMessageModal(true);
      return;
    }
    try {
      const { token, tokenStatus } = await getToken();

      if (tokenStatus !== 'valid') return null;

      const res = await fetch(`${getBaseURL(environment)}/mfa`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({
          mfa_channel: MFA.value,
          mfa_channel_value: MFA.value === 'email' ? dashboardData.email : MFAPhone
        })
      })
      
      if (res.status === 202) {
        setMFAVerify(true);
      } if (res.status === 403) {
        setMessage('Check phone number and try again.')
      } else {
        setMessage('We could not set up MFA at this time.')
      }
    }
    catch (e) {
      setAlert({ type: 1 });
      console.log(e)
    }
    finally {
      setLoading(false);
    }
  }, [getToken, setAlert, MFA, MFAPhone, dashboardData, setMessage, setLoading, environment])

  const showAccountDeleted = openModalDeleted
    ? <Modal
      isOpen={openModalDeleted}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>{message}</Message>
        <Row>
          <Button style={{ width: 'auto' }} onClick={() => handleLogout()}>Ok</Button>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showMFAEnable = MFAEnable
    ? <StyledModal
      isOpen={MFAEnable}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{minWidth: '23rem'}}>
        <Message style={{ fontSize: '1.2em', margin: '1rem' }}><strong>Multi Factor Authentication</strong></Message>
        <Message style={{ margin: '0 0 1rem 0' }}>If enabled, this will be required during login.</Message>
        {
          MFAVerify
            ? <>
                <Label>Enter One Time Password: </Label>
                <MFARow style={{ margin: '1rem' }}>
                  <Input
                    value={OTP}
                    name='OTP'
                    autoComplete='off'
                    style={{ width: '8rem' }}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </MFARow>
                <Row>
                  <Button
                    onClick={() => { setMFAEnable(false); setMessage(''); setMFAVerify(false); }}
                    style={{ marginRight: '2rem', width: '7rem' }}
                  >Cancel</Button>
                  <Button onClick={verifyMFA} style={{ width: '7rem' }}>Submit</Button>
                </Row>
              </>
            : <>
                <MFARow>
                  <Label style={{ marginRight: '2rem' }}>Method: </Label>
                  <StyledSelectMFA
                    value={MFA}
                    onChange={(selected) => setMFA(selected)}
                    options={mfaOptions}
                    isSearchable={false}
                    // className='custom-select'
                    styles={selectStyles}
                  />
                </MFARow>
                <MFARow style={{ margin: '1.5rem 1.5rem 0 1.5rem' }}>
                  {
                    MFA.value === 'email'
                      ? <Input
                          value={dashboardData ? dashboardData.email : ''}
                          name='email'
                          autoComplete='off'
                          disabled={true}
                          style={{ width: '14rem' }}
                      />
                      : <PhoneInput
                          onChange={setMFAPhone}
                          value={MFAPhone}
                          name='mfa-voice'
                          autoComplete='off'
                          defaultCountry='US'
                          style={{ height: 'auto' }}
                          placeholder='Phone Number'
                      />
                  }
                </MFARow>
                <Error>{message ? message : ''}</Error>
                <Row style={{ marginTop: '1rem' }}>
                  <Button onClick={() => { setMessage(''); setMFAEnable(false) }} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
                  <Button onClick={initMFA} style={{ width: '7rem' }}>Submit</Button>
                </Row>
              </>
        }
      </ModalContent>
    </StyledModal>
    : <></>
  
  const showConfirmDisableMFA = MFADisable
    ? <Modal
      isOpen={MFADisable}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em' }}><strong>Confirm Disable MFA</strong></Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>By clicking "Disable", Multi Factor Authentication will not be required to log in.</Message>
        <Row>
          <Button style={{ minWidth: '5rem' }} onClick={() => setMFADisable(false)}>Cancel</Button>
          <DeleteButtonInv onClick={() => { setMFADisable(false); disableMFA() }}>Disable</DeleteButtonInv>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  const showProfile = dashboardData
    ? <>
        <CardTitle><Icon><FontAwesomeIcon icon={faUser} /></Icon>Profile</CardTitle>
        <Desktop>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>
                <strong>API Key: </strong>{showKey && dashboardData.api_key}
              </ProfileLabel>
              {showKey 
                ? <Button 
                style={{marginLeft: '0', width: '8rem'}} 
                onClick={() => setShowKey(false)}
              ><i className="fas fa-key"/>hide key</Button> 
                : <Button 
                    style={{marginLeft: '0', width: '8rem'}} 
                    onClick={() => setShowKey(true)}
                  ><i className="fas fa-key"/>show key</Button> 
                  }
              <ProfileText>
              </ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>
                <strong>Alert Token: </strong>{showAlertToken && dashboardData.alert_token}
              </ProfileLabel>
              {showAlertToken 
                ? 
                <Button 
                    style={{marginLeft: '0', width: '8rem'}} 
                    onClick={() => setShowAlertToken(false)}
                ><i className="fas fa-key"/>hide token
                </Button>
                :
                <Button 
                  style={{marginLeft: '0', width: '8rem'}} 
                  onClick={() => setShowAlertToken(true)}
                ><i className="fas fa-key"/>show token
                </Button> 
              }
              <ProfileText>
              </ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>{'COMPANY:'}</ProfileLabel>
              <ProfileText>{dashboardData.company_name}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>ADDRESS:</ProfileLabel>
              {dashboardData.first_name
                ? dashboardData.company_address
                  ? <>
                      <ProfileText>{dashboardData.company_address}</ProfileText>
                      <ProfileText>
                        {`${dashboardData.company_city && dashboardData.company_city}, ${dashboardData.company_state} ${dashboardData.company_postal_code}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
                : dashboardData.company_address
                  ? <>
                      <ProfileText>{dashboardData.company_address}</ProfileText>
                      <ProfileText>
                      {`${dashboardData.company_city}, ${dashboardData.company_state} ${dashboardData.company_postal_code}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
              }
            </ProfileColumn>
            { environment === 1 ? <ProfileColumn>
              <ProfileLabel>MULTI FACTOR AUTHENTICATION:</ProfileLabel>
              <Toggle 
                id={`toggle-mfa`}
                isOn={dashboardData.mfa_enabled} 
                handleToggle={() => handleToggle()}
                small={true}
              />
            </ProfileColumn> : <></>}
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>EMAIL:</ProfileLabel>
              <ProfileText>{dashboardData.email}</ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel>USER ID:</ProfileLabel>
              <ProfileText>{dashboardData.id}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>PHONE:</ProfileLabel>
              <ProfileText>{dashboardData.phone}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
        </Desktop>

        <Mobile>
        <ProfileColumn>
              <ProfileLabel><strong>API Key:</strong></ProfileLabel>
              {showKey 
                ? <Button 
                style={{marginLeft: '0', width: '8rem'}} 
                onClick={() => setShowKey(false)}
              ><i className="fas fa-key"/>hide key</Button> 
                : <Button 
                    style={{marginLeft: '0', width: '8rem'}} 
                    onClick={() => setShowKey(true)}
                  ><i className="fas fa-key"/>show key</Button> 
                  }
                {showKey ? <>{dashboardData.api_key}</> : <></>}
              <ProfileText>
              </ProfileText>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileLabel><strong>Alert Token:</strong></ProfileLabel>
              {showAlertToken 
                ? 
                <Button 
                    style={{marginLeft: '0', width: '8rem'}} 
                    onClick={() => setShowAlertToken(false)}
                ><i className="fas fa-key"/>hide token
                </Button>
                :
                <Button 
                  style={{marginLeft: '0', width: '8rem'}} 
                  onClick={() => setShowAlertToken(true)}
                ><i className="fas fa-key"/>show token
                </Button> 
              }
              {showAlertToken ? <>{dashboardData.alert_token}</> : <></>}
              <ProfileText>
              </ProfileText>
            </ProfileColumn>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>{dashboardData.firstName ? 'NAME' : 'COMPANY:'}</ProfileLabel>
              <ProfileText>{dashboardData.companyName}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            <ProfileColumn>
              <ProfileLabel>ADDRESS:</ProfileLabel>
              {dashboardData.firstName
                ? dashboardData.address
                  ? <>
                      <ProfileText>{dashboardData.address}</ProfileText>
                      <ProfileText>
                        {`${dashboardData.city && dashboardData.city}, ${dashboardData.state} ${dashboardData.postalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
                : dashboardData.companyAddress
                  ? <>
                      <ProfileText>{dashboardData.companyAddress}</ProfileText>
                      <ProfileText>
                      {`${dashboardData.companyCity}, ${dashboardData.companyState} ${dashboardData.companyPostalCode}`}
                      </ProfileText>
                    </>
                  : <ProfileText></ProfileText>
              }
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>EMAIL:</ProfileLabel>
              <ProfileText>{dashboardData.email}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>PHONE:</ProfileLabel>
              <ProfileText>{dashboardData.phone}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
          <ProfileRow style={{minHeight: '4rem'}}>
            { environment === 1 ? <ProfileColumn>
              <ProfileLabel>MULTI FACTOR AUTHENTICATION:</ProfileLabel>
              <Toggle 
                id={`toggle-mfa`}
                isOn={dashboardData.mfa_enabled} 
                handleToggle={() => handleToggle()}
                small={true}
              />
            </ProfileColumn> : <></>}
          </ProfileRow>
          <ProfileRow>
            <ProfileColumn>
              <ProfileLabel>USER ID:</ProfileLabel>
              <ProfileText>{dashboardData.id}</ProfileText>
            </ProfileColumn>
          </ProfileRow>
        </Mobile>
      </>
    : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

  const showConfirmDelete = openModalConfirm
    ? <Modal
      isOpen={openModalConfirm}
      style={modalStyles}
      contentLabel="Form"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ fontSize: '1.1em' }}><strong>Confirm Account Deletion</strong></Message>
        <Message style={{ fontSize: '.9em', marginTop: '1rem' }}>By clicking "Delete", all personal account information will be deleted from bitdoor. You will be logged out once account deletion is complete.</Message>
        <Row>
          <Button style={{ minWidth: '5rem' }} onClick={() => setOpenModalConfirm(false)}>Cancel</Button>
          <DeleteButtonInv onClick={handleDelete}>Delete</DeleteButtonInv>
        </Row>
      </ModalContent>
    </Modal>
    : <></>

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        {showProfile}
        {showMFAEnable}
        {showConfirmDelete}
        {showAccountDeleted}
        {showConfirmDisableMFA}
      </Content>
    </Container>
  );
}

export default isLoading(Profile);

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  ${({ theme }) => theme.animations.fadeIn };

  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
    margin: auto;
  }

  @media (max-width: 414px) {
    margin: auto;
    padding: 2rem 0 0 0;
  }
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2rem;
  min-width: 5rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  };

  i {
    margin-right: .5rem;
  }
`

const ProfileRow = styled.div`
  display: flex;
  margin: 1.5rem 0 0 0;
  min-height: 3rem;
  @media (max-width: 414px) {
    margin-left: 3rem;
  }
`

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25rem;

  @media (max-width: 768px) {
    min-width: 10rem;
  }

`

const ProfileLabel = styled.label`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const ProfileText = styled.label`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.text};
`

// const Check = styled.span`
//   padding: .3rem auto;
//   margin: auto auto auto .2rem;
//   font-size: 1em;
//   color: ${({ theme }) => theme.colors.green};
// `

// const DeleteButton = styled.button`
//   color: #fff;
//   border: 1px solid ${({ theme }) => theme.colors.error};
//   background-color: ${({ theme }) => theme.colors.error};
//   height: 2rem;
//   min-width: 8rem;
//   font-size: 1em;
//   margin: .5rem auto auto 0;
//   font-weight: 700;
//   font-size: .9em;

//   :hover {
//     background-color: #fff;
//     color: ${({ theme }) => theme.colors.error};
//   };

// `

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  @media (max-width: 414px) {
    width: 85%;
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  text-align: center;
  max-width: 19rem;
  margin: auto 1rem;

  @media (max-width: 414px) {
    max-width: 15rem
    width: 100%;
  }
`

const Label = styled.label`
  font-size: 1em;
`

const MFARow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  align-items: center;
  margin: 0;
  height: 3rem;
  input {
    height: 1.5rem;
    width: 8rem;
  }
`

const Input = styled.input`
  padding: .4rem .75rem;
  height: 2rem;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const StyledSelectMFA = styled(Select)`
  width: 8rem;
  div {
    min-height: 1.3rem;
  }
  option {
    vertical-align: center;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: .9em;
  margin: 0;
  height: 1rem;
`

const DeleteButtonInv = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: ${({ theme }) => theme.colors.error};
  height: 2rem;
  min-width: 5rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.error};
  };

`