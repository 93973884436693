import React, { useState } from 'react';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ExportButton from '../common/ExportButton';
import Modal from 'react-modal';
import { modalStyles } from '../../Theme';
import { formatDateMonths } from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudDownloadAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faSearch
} from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root');

const Tracking = ({ trackingData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);

  const handleViewCompany = (data) => {
    setCurrentCompany(data);
    setOpenModal(true);
  }

  const handleCloseCompany = () => {
    setCurrentCompany(null);
    setOpenModal(false);
  }

  const tableDateFormatter = (cell, row) => {
    return (
      <span>{ formatDateMonths(cell) }</span>
    );
  };

  const trackingDataColumn = (cell, row) => {
    return (
      <Link onClick={() => handleViewCompany(row)}><FontAwesomeIcon icon={faCloudDownloadAlt}/></Link>
    );
  };

  const columns = [{
    dataField: 'company',
    text: 'Company',
    sort: false,
    headerStyle: {width: 'auto'},
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon={faSort} className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon={faSortUp} className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon={faSortDown} className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'type',
    text: 'Data Type',
    sort: false,
    headerStyle: {width: 'auto'},
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon={faSort} className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon={faSortUp} className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon={faSortDown} className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'data',
    text: 'Download Tracking Data',
    headerStyle: {
      textAlign: 'center'
    },
    style: {
      textAlign: 'center'
    },
    formatter: trackingDataColumn,
  }];

  const trackingDataColumns = [{
    dataField: 'lastOptIn',
    text: 'Opt In Date',
    sort: false,
    headerStyle: {verticalAlign: 'middle'},
    formatter: tableDateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon={faSort} className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon={faSortUp} className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon={faSortDown} className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'company',
    text: 'Company',
    sort: false,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon={faSort} className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon={faSortUp} className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon={faSortDown} className="custom-sort-icon-active" />
        );
      return null;
    }
  }, {
    dataField: 'type',
    text: 'Data Type',
    sort: false,
    sortCaret: (order, column) => {
      if (!order) return (
          <FontAwesomeIcon icon={faSort} className="custom-sort-icon" />
        );
      else if (order === 'asc') return (
          <FontAwesomeIcon icon={faSortUp} className="custom-sort-icon-active" />
        );
        else if (order === 'desc') return (
          <FontAwesomeIcon icon={faSortDown} className="custom-sort-icon-active" />
        );
      return null;
    }
  }];

  const showModal = currentCompany &&
    <Modal
      isOpen={openModal}
      style={modalStyles}
      contentLabel="Tracking Data"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <ModalRow>
          <ModalTitle>Tracking Data</ModalTitle>
          <CloseButton onClick={handleCloseCompany}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </ModalRow>
        <ModalRow style={{ margin: '1rem 0', justifyContent: 'center' }}>
          <ModalTableTitle>
            {currentCompany.company}
          </ModalTableTitle>
        </ModalRow>
        <ToolkitProvider
          keyField={'key'}
          data={[currentCompany]}
          columns={trackingDataColumns}
          exportCSV
        >
          {
            props => (
              <TableContainerModal>
                <BootstrapTable
                  {...props.baseProps}
                  // striped
                  hover
                />
                <ModalRow style={{ margin: '.5rem auto' }}>
                  <ExportButton
                    style={{ fontSize: '.8em', height: '2rem' }}
                    text={'Download'}
                    {...props.csvProps} />
                </ModalRow>
              </TableContainerModal>
            )
          }
        </ToolkitProvider>
      </ModalContent>
    </Modal>
  
  const trackingTable = () => {
    if (!trackingData) return null;
    if (!trackingData.length) {
      return <NoDataTitle>There are no companies tracking your data</NoDataTitle>
    };

    return(
      <TableContainer>
        <ToolkitProvider
          keyField={'key'}
          data={trackingData}
          columns={columns}
          exportCSV
        >
          {
            props => (
              <DataTableContainer>
                <BootstrapTable
                  {...props.baseProps}
                  // striped
                  hover
                />
              </DataTableContainer>
            )
          }
        </ToolkitProvider>
        {showModal}
      </TableContainer>
    )
  };
      

  return (
    <Content>
      <CardTitle>
        <Icon><FontAwesomeIcon icon={faSearch} className="icon" /></Icon>
        Who's Tracking You
      </CardTitle>
      {trackingTable()}
    </Content>
  );
}

export default Tracking;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  width: auto;
  padding: 3rem 3rem 0 3rem;
  ${({ theme }) => theme.animations.fadeIn};
  font-size: 1em;
  margin-bottom: 2rem;
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-content: center;
  padding: .5rem auto;
`

const Icon = styled.div`
  display: flex;
  align-content: center;
  padding: .3rem auto;
  color: ${({ theme }) => theme.colors.primary };
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.textSecondary };
  cursor: pointer;
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  margin: 1.5rem;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
  height: 4rem;
  @media (max-width: 375px) {
    text-align: center;
  }
`

const TableContainer = styled.div`
  font-size: 1em;
  margin: 0;
  max-width: fit-content;
  table {
    border: none;
  };
  table td {
    border-top: 1px solid #dee2e6;
  }
`

const TableContainerModal = styled.div`
  font-size: 1em;
  margin: 0;
  padding: 0;
  max-width: fit-content;
  table {
    border: none;
  };
  table td {
    border-top: 1px solid #dee2e6;
    padding: .25rem .5rem;
  }
  table th {
    padding: .25rem .5rem;
    vertical-align: bottom;
  }
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  div.react-bootstrap-table {
    max-height: 30rem;
    overflow: scroll;
  }

  div.react-bootstrap-table table {
    width: auto;
    font-size: .8em;
    border: 0;
    text-transform: none;
  }
`

const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTitle = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
`

const ModalTableTitle = styled.div`
  font-size: 1.3em;
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`
