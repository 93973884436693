import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronRight, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../services/utils';
import Loading from 'react-loading';
import isLoading from './common/isLoading';
import pentaLogo from '../images/pentadata_logo_170.png';
import { commonBanks } from '../services/commonBanks';

const AccountLite = ({ setAlert, setMessage, setLoading }) => {
    const [searchStr, setSearchStr] = useState('');
    const [searching, setSearching] = useState(false);
    const [institutions, setInstitutions] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [bankList, setBankList] = useState([]);

    let { qstr } = useParams();
    const { signature, redirectUri } = queryString.parse(qstr);

    const fetchBanks = async () => {
        const res = await fetch('banks_ui.json')
        const data = await res.json()
        setBankList(data)
    }

    const filterInstitutions = async (query) => {
        let results = [];
        bankList.forEach(i => {
            const name = i.name.toLowerCase(0)
            if (name.includes(query)) {
                results.push(i)
            }
        })
        return results;
    }

    const searchInstitutions = async () => {
        if (!searchStr) return;
        setSearching(true);
        setErrorMsg('');
        const lowerStr = searchStr.toLowerCase()
        const query = commonBanks[lowerStr] ? commonBanks[lowerStr] : lowerStr
        try {
            const results = await filterInstitutions(query);
            if (!results.length) {
                setErrorMsg('No results found');
                setInstitutions(null);
                return;
            }
            setInstitutions(results);
        }
        catch {
            console.log('Error searching institutions')
        }
        finally {
            setSearching(false);
        }
    }

    const getUrl = async({ id }) => {
        setLoading(true);
        try {
            const res = await fetch(`${BASE_URL}/fin-accounts`, {
                method: 'POST',
                headers: new Headers({
                    'Penta-Fin-Signature': signature
                }),
                body: JSON.stringify({
                    bank: id,
                    redirect_uri: redirectUri
                })
            })
            if (res.status === 200) {
                const { url } = await res.json();
                window.location.assign(url);
            } else {
                setMessage('We’re unable to connect to your bank at this time. Please try again later.')
                setAlert({ type: 0 })
            }
        }
        catch(e) {
            console.log('Error getting URL', e);
            setAlert({ type: 1 });
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchBanks();
        // eslint-disable-next-line
    }, [])

    const Result = ({ name, id, imgUrl }) => {
        const [show, setShow] = useState(false);
        return(
            <ResultRow
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                onClick={() => getUrl({ id })}
            >
                {imgUrl
                    ?   <Logo src={imgUrl ? imgUrl : pentaLogo} />
                    :   <LogoDefault>
                            <FontAwesomeIcon icon={faUniversity} />
                        </LogoDefault>
                }
                {name}
                {show &&
                    <ResultChevron>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </ResultChevron>
                }
            </ResultRow>
        )
    }

    const results = () => {
        if (!institutions) {
            return(
                <Row>{errorMsg}</Row>
            )
        }
        return(
            <Results>
                {institutions.map(({ name, id, branding }, idx) => (
                    <Result
                        key={`institution-${idx}`}
                        name={name}
                        imgUrl={branding && branding.icon}
                        id={id}
                    />
                ))}
            </Results>
        )
    }

    return (
        <Container>
            <Card id='connect-container-lite'>
                <Row>
                    <Title>Search Institutions</Title>
                </Row>
                <Row style={{ margin: 'auto' }}>
                    <Input
                        onChange={(e) => setSearchStr(e.target.value)}
                        placeholder="Search.."
                        value={searchStr}
                        onKeyDown={e => e.key === 'Enter' && searchInstitutions()}
                    />
                    <Button onClick={searchInstitutions}>
                        {searching 
                            ? <Loading type={'spin'} height={15} width={15} />
                            : <FontAwesomeIcon icon={faSearch} />
                        }
                    </Button>
                </Row>
                {results()}
            </Card>
        </Container>
    );
}

export default isLoading(AccountLite);


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #3a3a3a;
    text-align: center;
`

const Row = styled.div`
    display: flex;
    justify-content: center;
`

const Card = styled.div`
    display: flex;
    width: 24rem;
    min-height: 10rem;
    max-height: 32rem;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: .25rem;
    background-color: #fff;
    padding: 2rem 2.5rem 2.5rem 2.5rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    
    @media (max-width: 414px) {
        width: 17rem;
    }
`

const Input = styled.input`
    height: 1.25rem;
    width: 10rem;
    font-size: 1.1em;
`

const Button = styled.button`
    color: #fff;
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    margin-left: 1rem;
    padding: .5rem .75rem;
    font-size: 1.1em;
`

const Title = styled.div`
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1rem;
`

const Logo = styled.img`
    margin-right: 1rem;
    max-width: 2.5rem;
    min-width: 2.5rem;
`

const LogoDefault = styled.div`
    margin-right: 1rem;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary };
    svg {
        color: #fff;
        margin-bottom: 0.15em;
        font-size: 1.4em;
    }
`

const Results = styled.div`
    ${({ theme }) => theme.animations.fadeUp };
    height: 26rem;
    overflow: scroll;
    width: 100%;
    margin-top: 1rem;
`

const ResultRow = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    margin-top: 1rem;
    text-align: left;
`

const ResultChevron = styled.div`
    margin-right: 1rem;
    margin-left: auto;
    ${({ theme }) => theme.animations.fadeRight };
    svg {
        color: ${({ theme }) => theme.colors.primary };
    }
`

