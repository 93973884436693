import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt, faTrashAlt, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL, formatDate } from '../../services/utils';
import isLoading from '../common/isLoading';
import { modalStyles, modalStylesI } from '../../Theme';
import Modal from 'react-modal';

const Accounts = ({
    setAlert,
    setMessage,
    fetchAccountsData,
    accountsData,
    getToken,
    setLoading,
    fetchTrackingData
}) => {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [modalAccount, setModalAccount] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const refresh = useCallback(async () => {
        if (!disabled) {
            setDisabled(true);
            let { token } = await getToken();
            if (token) {
                fetchAccountsData({ token });
            }
            setTimeout(() => {
                setDisabled(false);
            }, 2000)
        }
    }, [disabled, fetchAccountsData, getToken])

    const addAccount = useCallback((url) => {
        setModalAccount(true);
        window.finicityConnect.launch(url, {
            selector: '#connect-container',
            overlay: 'rgba(59, 59, 59, 0.75)',
            success: function (data) {
                setMessage('New accounts may take a few minutes to process. Please try refreshing accounts.');
                setAlert({ type: 0 });
                setModalAccount(false);
            },
            cancel: function () {
                console.log('The user cancelled the iframe');
                setModalAccount(false);
            },
            error: function (err) {
                console.error('Runtime error', err);
                setModalAccount(false);
            },
            loaded: function () {
                console.log('Finished loading');
            },
            route: function (event) {
                console.log('Progressing');
            }
        })
    }, [setMessage, setAlert])

    const deleteAccount = useCallback(async ({ id, type }) => {
        setLoading(true);
        let { token } = await getToken();
        if (!token) return;
        fetch(`${BASE_URL}/accounts/${id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }),
        })
        .then(res => {
            setLoading(false);
            if (res.status === 202) {
                setMessage('Account removed.');
                setAlert({ type: 0 });
                fetchAccountsData({ token });
                fetchTrackingData({ token });
            } else {
                setAlert({ type: 0 });
                setMessage('There was a problem deleting this account.');
            }
            setCurrentAccount(null);
        })
        .catch(err => {
            console.log("Error deleting card", err)
            setAlert({ type: 0 });
        })
    }, [getToken, fetchAccountsData, fetchTrackingData, setLoading, setAlert, setMessage])

    const initAccount = useCallback(async () => {
        setLoading(true);
        let { token } = await getToken();
        if (!token) return;
        try {
            let response = await fetch(`${BASE_URL}/accounts/init`, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
            })
            let data = await response.json()
            if (data.url) {
                addAccount(data.url);
            } else {
                setAlert({ type: 1 });
            }
        }
        catch (e) {
            setAlert({ type: 1 });
            console.log(e)
        }
        finally {
            setLoading(false);
        }
    }, [getToken, setLoading, setAlert, addAccount])

    const accountTable = () => {

        if (!accountsData) return null;
        if (!accountsData.length) {
            return(
                <NoDataTitle>No accounts enrolled</NoDataTitle>
            )
        }

        return(
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Opt In Date</th>
                        <StyledTh>Remove</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {accountsData.map((account, idx) => (
                        <tr key={`a-enroll-${idx}`}>
                            {account.accountName !== null && account.accountName !== '' && account.accountName !== undefined
                                ? <td>{`${account.bank} - ${account.accountName}`}</td>
                                : <td>{`${account.bank}`}</td>
                            }
                            <td>{formatDate(account.lastOptIn)}</td>
                            <StyledTd>
                                <TrashIcon 
                                    onClick={() => { setCurrentAccount(account); setModalConfirm(true) }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </TrashIcon>
                            </StyledTd>
                        </tr>
                    ))}
                </tbody>
            </table>
        )

    }

    const handleCancel = () => {
        setModalConfirm(false);
        setMessage('');
        setCurrentAccount(null);
    }

    const handleDelete = () => {
        setModalConfirm(false);
        deleteAccount({ id: currentAccount.accountId });
    }

    const showModalConfirm = currentAccount &&
        <Modal
            isOpen={modalConfirm}
            style={modalStyles}
            contentLabel="Notification"
            overlayClassName="modal-overlay"
        >
            <ModalContent>
                <Message style={{ minWidth: '12rem', maxWidth: '21rem' }}>
                    Are you sure you want to remove {currentAccount.bank} {currentAccount.accountName && ` - ${currentAccount.accountName}`}
                </Message>
                <Row>
                    <Button onClick={() => handleCancel()}>Cancel</Button>
                    <Button onClick={() => handleDelete()}>Remove</Button>
                </Row>
            </ModalContent>
        </Modal>
    
    const showModalAddAccount = modalAccount &&
        <Modal
            isOpen={modalAccount}
            style={modalStylesI}
            contentLabel="Form"
            overlayClassName="modal-overlay"
        >
            <ModalContent>
                <div id='connect-container' style={{ height: '600px', width: '400px' }}>
                </div>
            </ModalContent>
        </Modal>

    return (
        <Content>
            <Row style={{ justifyContent: 'space-between' }}>
                <CardTitle><Icon><FontAwesomeIcon icon={faUniversity} /></Icon>Accounts</CardTitle>
                <Row>
                    <RefreshIcon onClick={refresh} disabled={disabled}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </RefreshIcon>
                    <AddLink onClick={initAccount}><FontAwesomeIcon icon={faPlus} /></AddLink>
                </Row>
            </Row>
            <TableContainer>
                {accountTable()}
            </TableContainer>
            {showModalConfirm}
            {showModalAddAccount}
        </Content>
    )
};

export default isLoading(Accounts);

const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    width: auto;
    padding: 3rem 3rem 0 3rem;
    ${({ theme }) => theme.animations.fadeIn};
`

const Row = styled.div`
    display: flex;
    justify-content: center;
`

const Icon = styled.div`
    padding: .3rem auto;
    margin-right: 1rem;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.primary};
`


const TableContainer = styled.div`
    margin: 2rem auto 1rem auto;
    width: 100%;
    font-size: 1em;
    tbody tr:hover {
        color: #212529;
        background-color: rgba(0, 0, 0, 0.075);
    }
`

const RefreshIcon = styled.div`
    display: flex;
    font-size: 1em;
    align-items: center;
    padding: .2rem;
    cursor: pointer;
    margin-right: 1rem;

    svg {
        color: ${({ disabled, theme }) => disabled ? theme.colors.textSecondary : theme.colors.primary};
        ${({ disabled, theme }) => disabled && theme.animations.spin};
    }
`

const CardTitle = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.6em;
    font-weight: 500;
    align-items: center;
    padding: .5rem auto;
`

const Message = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.1em;
    margin: 1rem 2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
`

const ModalContent = styled.div`
    ${({ theme }) => theme.animations.fadeUp};
    max-height: 40rem;
`

const Button = styled.button`
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1em;
    height: 2rem;
    padding: .4rem 1.2rem;
    margin: 1rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.primary};

    &:hover {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
        color: #fff;
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
`

const TrashIcon = styled.span`
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.error};
    }
`

const NoDataTitle = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.4em;
    font-weight: 400;
    align-items: center;
    padding: .5rem auto;
    justify-content: center;
`

const StyledTd = styled.td`
    text-align: center;
    vertical-align: center;
`

const StyledTh = styled.th`
    text-align: center;
    vertical-align: center;
`

const AddLink = styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.1em;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: .5rem;
`
