import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Loading from 'react-loading';
import Accounts from './Accounts';
import Cards from './Cards';

const AccountsCards = ({ 
  view, handleView, getToken, personCards, personAccounts, fetchPersonAccounts,
  setPersonAccounts, setPersonCards, accountSearch, setAccountSearch, currentPerson,
  setCurrentPerson
}) => {
  const [searchMsg, setSearchMsg] = useState('Input Person ID to show results.');
  const [personId, setPersonId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async(personId) => {
    if(!personId) {
      setSearchMsg('Person ID required');
      return;
    }
    setLoading(true);
    setCurrentPerson(null);
    setPersonCards(null);
    setPersonAccounts(null);
    const { token } = await getToken();
    if (!token) return;
    try {
      const accounts = await fetchPersonAccounts({personId, endpoint: 'accounts', token})
      const cards = await fetchPersonAccounts({personId, endpoint: 'cards', token})
      if (!accounts && !cards) {
        setSearchMsg('No results found.')
        return;
      }
      setCurrentPerson(personId);
    }
    catch(e) {
      console.log('error searching accounts', e)
    }
    finally {
      setLoading(false);
    }
  }, [fetchPersonAccounts, setPersonAccounts, setPersonCards, getToken, setCurrentPerson])

  useEffect(() => {
    if (accountSearch) {
      handleSearch(accountSearch);
      setAccountSearch('');
    }
  }, [accountSearch, handleSearch, setAccountSearch])

  const showTable = () => {
    if(loading) {
      return(
        <StandAloneContainer style={{alignItems: 'flex-start', padding: '2rem'}}>
          <Loading type={'spin'} color={'#424242'} height={30} width={30}/>
        </StandAloneContainer>
      )
    }
    if(personAccounts || personCards) {
      return(
        <>
          <Accounts personAccounts={personAccounts}/>
          <Cards personCards={personCards} />
        </>
      )
    } else {
      return(
        <StandAloneContainer style={{alignItems: 'flex-start'}}>
          <NoDataText>
            {searchMsg}
          </NoDataText>
        </StandAloneContainer>
      )
    }
  }

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <Row style={{height: '4rem', justifyContent: 'flex-start', marginTop: '1rem'}}>
            <Input 
              placeholder='Person ID'
              onChange={(e) => setPersonId(e.target.value)}
              type='number'
              value={personId}
            />
            <Button onClick={() => handleSearch(personId)}>
              <i className="fas fa-search"></i>
            </Button>
            {currentPerson && <CardTitle>Person ID: {currentPerson}</CardTitle>}
          </Row>
          {showTable()}
        </Card>
      </Content>
    </Container>
  );
}

export default AccountsCards;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  height: 100%;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const NoDataText = styled.div`
  font-size: 1.4em;
  margin: 2rem;
`

const Input = styled.input`
  height: 1rem;
  margin-right: 1rem;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  font-size: 1.1em;
  text-align: center;
  width: 2rem;
  padding: .4rem;
  height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
  margin-left: 1rem;
`