import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getBaseURL } from '../services/utils';
import Modal from 'react-modal';
import pentadataLogo from '../images/pentadata_logo_170.png';
import PasswordValidation from './common/PasswordValidation'
import Toggle from './common/ToggleSwitch';

Modal.setAppElement('#root');

const ResetPassword = ({ setMessage, setAlert, emailSt, environment, setEnvironment }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [invalidMessage, setInvalidMessage] = useState('');
 
  const handleToggle = useCallback(() => {
    if (environment) {
      setEnvironment(0)
    } else {
      setEnvironment(1)
    }
   }, [environment, setEnvironment])

  const handleSubmit = useCallback(async() => {
    setInvalidMessage('');
    const tempEmail = email || emailSt;
    const creds = { email: tempEmail, password1: password, password2 }
    try {
        const res = await fetch(getBaseURL(environment) + '/reset-password', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify(creds)
        })
        const data = await res.json();
        switch(res.status) {
            case 200:
                setAlert({ type: 0, login: true });
                setMessage(data.message);
                setPassword('');
                setPassword2('');
                break;
            case 400:
                setAlert({ type: 0 });
                setMessage(data.message);
                break;
            case 401:
                // setAlert({ type: 0, signup: true }) // Enable when sign up is ready
                setAlert({ type: 0 })
                setMessage(data.message);
                break;
            default:
                setAlert({ type: 1 })
                break;
        }
      } 
    catch(e) {
        console.log('Error resetting pw', e)
        setAlert({ type: 1 });
      }
  }, [email, emailSt, password, password2, setMessage, setAlert, environment])

  const isFormValid = useCallback(() => {
    const tempEmail = email || emailSt;
    if (tempEmail && password && password2) {
      if (password === password2) {
        handleSubmit();
      } else {
        setInvalidMessage('Passwords do not match')
      }
    } else {
      setInvalidMessage('Form incomplete');
    }
  }, [email, emailSt, password, password2, handleSubmit])

  return (
    <Container>
      <LoginForm>
        <LogoTitle src={pentadataLogo} />
        <TitleText>Set Password</TitleText>
          <Form>
            { emailSt
                ? <Input value={emailSt} disabled={true} />
                : <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='email' />
            }
            <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='new-password' />
            <Input value={password2} onChange={e => setPassword2(e.target.value)} name='password confirm' type='password' placeholder='Confirm Password' autoComplete='confirm-password' /> 
          </Form>
          <Row>
            <Message>{invalidMessage}</Message>
          </Row>
          <Row>
            <Text>{environment === 0 ? "Sandbox" : "Production"} </Text>
            <Toggle 
              id={`toggle-environment`}
              isOn={environment} 
              handleToggle={handleToggle}
            />
          </Row>
          <PasswordValidation password={password}></PasswordValidation>
          <Button onClick={isFormValid} >Submit</Button>
          <Link to='/'><Button>Back to Log In</Button></Link>
      </LoginForm>
    </Container>
  );
}

export default ResetPassword;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginForm = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.pentaPrimary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;
  width: 100%;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  color: red;
  vertical-align: center;
  height: 1rem;
  display: flex;
  align-items: center;
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`

const Text = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.text };
`