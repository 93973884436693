import React from 'react'
import styled from 'styled-components';
import Modal from 'react-modal';
import { modalStyles } from './Theme';

export default function Alerts({ 
    alert, message, setMessage, setView, setAlert, dashboardData, sendSlack, slackMsg, setSlackMsg
}) {
    /*
        alert(object):
            type: 0 - notification with options
            contact: bool
                EX:
                    setAlert({ type: 0, contact: true });
                    setMessage('This is a notifiction with a contact button');
            reset: bool,
            login: bool,
            signup: bool,
            slack: bool, email: required when passing slack
                EX:
                    setSlackMsg('Default message to send to slack');
                    setAlert({ type: 0 , slack: true, email });

                    - Slack button will show an input form with submit

            type: 1 - generic error

            type: 2 - slack support form

    */

    switch (alert.type) {
        // Notification 
        case 0:
            return (
                <Modal
                    isOpen={true}
                    style={modalStyles}
                    contentLabel="Notification"
                    overlayClassName="modal-overlay"
                >
                    <ModalContent>
                        <Message style={{ maxWidth: '16rem', minWidth: '10rem' }}>
                            {message} &nbsp;
                        </Message>
                        <Row>
                            {alert.contact &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        setView('support');
                                        setAlert({ type: null });
                                    }}>
                                    Contact
                                </Button>}
                            {alert.reset &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        window.location.replace('/#/set-pw')
                                        setAlert({ type: null });
                                    }}>
                                    Reset Password
                                </Button>}
                            {alert.signup &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        window.location.replace('/#/signup')
                                        setAlert({ type: null });
                                    }}>
                                    Signup
                                </Button>}
                            {alert.login &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        window.location.replace('/')
                                        setAlert({ type: null });
                                    }}>
                                    Log In
                                </Button>}
                            {alert.loginConsumer &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        window.location.replace('/#/consumers/login')
                                        setAlert({ type: null });
                                    }}>
                                    Log In
                                </Button>}
                            {alert.slack &&
                                <Button
                                    style={{ margin: '0 1rem 0 0' }}
                                    onClick={() => {
                                        setMessage('');
                                        setAlert(prevState => ({ ...prevState, type: 2}));
                                    }}>
                                    Support
                                </Button>}
                            {alert.action
                                ?   <Button
                                        style={{ margin: '0' }}
                                        onClick={() => {
                                            setMessage('');
                                            setAlert({ type: null });
                                            alert.action();
                                        }}>Ok</Button>
                                :   <Button
                                        style={{ margin: '0' }}
                                        onClick={() => {
                                            setMessage('');
                                            setAlert({ type: null });
                                        }}>Ok</Button>
                            }
                            
                        </Row>
                    </ModalContent>
                </Modal>
            )

        // Errors
        case 1:
            return (
                <Modal
                    isOpen={true}
                    style={modalStyles}
                    contentLabel="Notification"
                    overlayClassName="modal-overlay"
                >
                    <ModalContent>
                        <Message style={{ maxWidth: '21rem' }}>
                            Sorry, an error occured please try again our contact us at &nbsp;
                        </Message>
                        <Link onClick={() => window.open("mailto:support@pentadatainc.com", "_blank")}>support@pentadatainc.com</Link>
                        {/* {modalMessage && <Message style={{maxWidth: '21rem'}}>{modalMessage}</Message>} */}
                        <Row>
                            {dashboardData && <Button onClick={() => { setAlert({ type: null }); setView('support'); setMessage('') }}>Support</Button>}
                            <Button onClick={() => { setAlert({ type: null }); setMessage('') }}>Close</Button>
                        </Row>
                    </ModalContent>
                </Modal>
            )

        // Slack account lock
        case 2:
            return (
                <Modal
                    isOpen={true}
                    style={modalStyles}
                    contentLabel="Notification"
                    overlayClassName="modal-overlay"
                >
                    <ModalContent>
                        <Title>Account Lock Support</Title>
                        <TextInput 
                            value={slackMsg}
                            onChange={(e) => setSlackMsg(e.target.value)}
                            type='text'
                        />
                        <Row>
                            <Button
                                style={{ margin: '0 1rem 0 0' }}
                                onClick={() => {
                                    setMessage('');
                                    setAlert({ type: null });
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                style={{ margin: '0' }}
                                onClick={() => {
                                    setMessage('');
                                    setAlert({ type: null });
                                    sendSlack(`Account Lock: ${slackMsg} ${alert.email}`);
                                }}
                            >
                                Submit
                            </Button>
                        </Row>
                    </ModalContent>
                </Modal>
            )

        default:
            return (null)
    }
}

const Title = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
`

const Message = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.1em;
    margin: 1rem 2rem 2rem 2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
`

const ModalContent = styled.div`
    ${({ theme }) => theme.animations.fadeUp};
    max-height: 40rem;
    text-align: center;
`

const Row = styled.div`
    display: flex;
    justify-content: center;
`

const Button = styled.button`
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1em;
    height: 2rem;
    padding: .4rem 1.2rem;
    margin: 1rem 1rem;
    min-width: 6rem;
    border: 1px solid ${({ theme }) => theme.colors.primary};

    &:hover {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
        color: #fff;
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
`

const Link = styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
`

const TextInput = styled.textarea`
    height: 3rem;
    margin: 1rem;
    width: 18rem;
    resize: vertical;
    font-size: 1em;
    @media (max-width: 414px) {
        max-width: 90%;
    }
`