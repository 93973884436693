import React from 'react';
import styled from 'styled-components';
import ContributorCircleBar from '../common/ContributorCircleBar';
import ProgressProvider from '../common/ProgressProvider';
import HorizontalBar from '../common/HorizontalBar';
import ReactVisibilitySensor from 'react-visibility-sensor'


const Overview = ({ dashboardData, handleView }) => {

  const mockDataUsage = [
    {name: 'API calls', value: 45000},
  ]

  const mockTotalCost = [
    {name: 'Total Cost', value: 230000}
  ]

  return (
    <Container>
      <Welcome>
        <Title>Data Partner Dashboard</Title>
        <Text>
          Welcome to the Pentadata Data Partner Dashboard. Use the tools below to review data subscribers, 
          consumers, usage, consents, and use cases.
        </Text>
      </Welcome>
      <Cards>
      <Card>
          <CardTitle><Icon><i className="icon fas fa-download"></i></Icon>Data Subscribers</CardTitle>
          <CardText>See which companies are using your data that you have provided.</CardText>
          <ProgressProvider valueStart={0} valueEnd={18}>
            {value => <ContributorCircleBar value={value} total={50} text={'active data'} text2={'subscribers'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {handleView('data subscribers')}}>View Details</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-users"></i></Icon>Consumers</CardTitle>
          <CardText>Review a list of consumers who have opted in to data sharing.</CardText>
          <ProgressProvider valueStart={0} valueEnd={12}>
            {value => <ContributorCircleBar value={value} total={50} text={'consumer'} text2={'opt-ins'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {handleView('consumers')}}>Review</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-chart-line"></i></Icon>Data Usage / Revenue</CardTitle>
          <CardText>View data usage by subscriber along with API calls and your revenue.</CardText>
          <Column>
            <HorizontalBar text={'API Calls'} data={mockDataUsage} width={200}/>
            <HorizontalBar text={'Total Cost'} data={mockTotalCost} width={300}/>
          </Column>
          <Row>
            <Button onClick={() => {handleView('data usage')}}>View Details</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-user-check"></i></Icon>Consumer Consents</CardTitle>
          <CardText>
            Review individual consumer consents including opt in date, form, and source.
          </CardText>
          <ReactVisibilitySensor />
          <ProgressProvider valueStart={0} valueEnd={12}>
            {value => <ContributorCircleBar value={value} total={50} text={'consumer'} text2={'consents'}/>}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {handleView('consumer consents')}}>Review</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-edit"></i></Icon>Merchant Consents</CardTitle>
          <CardText>
            Review individual merchant consents including opt in date and source.
          </CardText>
          <ProgressProvider valueStart={0} valueEnd={2}>
            {value => <ContributorCircleBar value={value} total={6} text={'merchant'} text2={'consents'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => handleView('merchant consents')}>Review</Button>
          </Row>
        </Card>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-chart-bar"></i></Icon>Data Subscriber Use Cases</CardTitle>
          <CardText>See the individual use cases for the data you've provided.</CardText>
          <ProgressProvider valueStart={0} valueEnd={16}>
            {value => <ContributorCircleBar value={value} total={50} text={'use cases'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {handleView('use cases')}}>Review</Button>
          </Row>
        </Card>
      </Cards>
    </Container>
  );
}

export default Overview;

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
`

const Welcome = styled.div`
  width: 100%;
  text-align: left;
  margin: 2rem 5rem;
`
const Title = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
`
const Text = styled.p`
  color: ${({ theme }) => theme.colors.text };
`

const Cards = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 3rem 5rem;

  @media (min-width: 1400px) {
    justify-content: center;
  }
`

const Card = styled.div`
  margin: 2rem;
  flex: 0 0 20%;
  display: flex;
  min-width: 22rem;
  max-width: 22rem;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  
  @media (min-width: 1400px) {
    width: 21rem;
  }
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  color: #424242;
  font-size: 1.6em;
  font-weight: 500;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: auto auto 0 auto;
  padding: .5rem 1.1rem;
  font-size: 1.1em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: .2rem;
`
