import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory,
  {
    PaginationProvider,
    PaginationTotalStandalone,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
  } from 'react-bootstrap-table2-paginator';
// import { Search } from 'react-bootstrap-table2-toolkit';
import Loading from 'react-loading';
import { formatDateSlash } from '../../services/utils';
import { AppContext } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'


const Persons = () => {
  const [search, setSearch] = useState('')
  const [searchPerson, setSearchPerson] = useState(false)

  const {
    view,
    handleView,
    personData,
    setPersonData,
    fetchPerson,
    fetchPersons,
    fetchPersonAccounts,
    getToken,
    environment,
    setCurrentPerson,
    personsLimit,
    setPersonsLimit,
    personsOffset,
    setPersonsOffset
  } = useContext(AppContext)

  const handleLink = useCallback(async(personId) => {
    const { token } = await getToken();
    if (!token) return;
    fetchPersonAccounts({personId, endpoint: 'accounts', token});
    fetchPersonAccounts({personId, endpoint: 'cards', token});
    handleView('accounts');
    setCurrentPerson(personId);
  }, [handleView, fetchPersonAccounts, getToken, setCurrentPerson])

  const handleSearch = useCallback(async() => {
    if (!search) return
    const { token } = await getToken()
    const res = await fetchPerson({ id: search, token })
    if (res) {
      setSearchPerson(true)
    }
  }, [search, fetchPerson, getToken])

  const handleReset = async() => {
    if (!search) return
    const { token } = await getToken()
    fetchPersons({ token, environment })
    setSearchPerson(false)
  }

  // const { SearchBar } = Search;

  const LongTextFormat = (cell, row) => {
    return (
      <LongText>{cell}</LongText>
    );
  };

  const DateFormat = (cell, row) => {
    return(
      <>{formatDateSlash(cell)}</>
    )
  }

  const LinkFormat = (cell, row) => {
    return (
      <Link onClick={() => handleLink(cell)}>{cell}</Link>
    )
  }

  const columns = [{
    dataField: 'id',
    text: 'Person ID',
    sort: true,
    formatter: LinkFormat,
    headerStyle: {
      width: '12%'
    },
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
      else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'first_name',
    text: 'First Name',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'last_name',
    text: 'Last Name',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerStyle: {
      width: '30%'
    },
    formatter: LongTextFormat,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  },
  {
    dataField: 'last_opt_in',
    text: 'Opt-In Date',
    sort: true,
    formatter: DateFormat,
    headerStyle: {
      width: '12%'
    },
    // API returns 'YYYYMMDD' which allows sorting as a string.
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }];

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true
  // };

  if (!personData) {
    return(
      <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>
      )
    }

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { personData.total } entries
    </DataTableText>
  );

  const options = {
    custom: true,
    page: personsOffset,
    sizePerPage: personsLimit,
    totalSize: personData.total,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
  };

  const sortColumn = (sortField, sortOrder) => {
    let newPersonData = {...personData}
    if (sortOrder === 'asc') {
      newPersonData.persons.sort((a, b) => {
        return ('' + a[sortField]).localeCompare(('' + b[sortField]), 'en', { numeric: true, sensitivity: 'base' })
      })
    } else {
      newPersonData.persons.sort((a, b) => {
        return ('' + b[sortField]).localeCompare(('' + a[sortField]), 'en', { numeric: true, sensitivity: 'base' })
      })
    }
    setPersonData(newPersonData)
  }

  const updatePage = async (offset, limit) => {
    const { token } = await getToken()
    if (!token) return
    fetchPersons({ token, environment, offset, limit })
  }

  const onTableChange = (type, props) => {
    const { sortField, sortOrder, page, sizePerPage } = props
    if (type === 'sort') {
      sortColumn(sortField, sortOrder)
    }
    if (type === 'pagination') {
      setPersonsLimit(sizePerPage)
      setPersonsOffset(page)
      if (searchPerson) return
      updatePage(page, sizePerPage)
    }
  }

  const showTable = (
      <TableContainer>
        <PaginationProvider pagination={ paginationFactory(options) }>
          {({ paginationProps, paginationTableProps}) => (
              <div>
                <DataTableContainer>
                  <Row style={{height: '5rem'}}>
                    <CardTitle><Icon><i className="fas fa-users"></i></Icon>Persons</CardTitle>
                    <StandAloneContainer>
                      <SearchBar
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder='Person ID'
                      />
                      <Button onClick={handleSearch}>
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                      <Button onClick={handleReset}>
                        Reset
                      </Button>
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                      />
                      <DataTableText>
                        items/page
                      </DataTableText>
                    </StandAloneContainer>
                  </Row>
                  <BootstrapTable
                    {...paginationTableProps}
                    keyField={"email"}
                    data={personData.persons}
                    columns={columns}
                    striped
                    hover
                    remote
                    onTableChange={onTableChange}
                    // selectRow={ selectRow }
                  />
                  <Row>
                    <PaginationTotalStandalone
                      { ...paginationProps }
                    />
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </Row>
                  {/* <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } /> */}
                </DataTableContainer>
              </div>
            )
          }
        </PaginationProvider>
      </TableContainer>
    )
  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          {showTable}
        </Card>
      </Content>
    </Container>
  );
}

export default Persons;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  height: 100%;
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 100vh;
`

const TableContainer = styled.div`
  font-size: .9em;
  margin: 0;
  padding: 0;
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
  table td {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 6rem;
    vertical-align: middle;
    @media (max-width: 768px) {
      max-width: 4rem;
    }
  }
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const LongText = styled.div`
  text-overflow: ellipsis;
  max-width: 18rem;
  text-transform: none;
`

const Link = styled.span`
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 500;
  cursor: pointer;
`

const SearchBar = styled.input`
  height: 1.6rem;
  margin-right: 1rem;
`

const Button = styled.button`
	background-color: ${({ theme }) => theme.colors.buttonPrimary};
	min-width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
`
