import React from 'react';
import styled from 'styled-components';

const SideNav = ({ view, handleView }) => {
  return (
    <Container>
      <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
        <Link onClick={() => handleView('overview')}>
          <Icon><i className="fas fa-info-circle"></i></Icon>Overview
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'data subscribers' ? '700' : ''}`}}>
        <Link onClick={() => handleView('data subscribers')}>
          <Icon><i className="fas fa-download"></i></Icon>Data Subscribers
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'consumers' ? '700' : ''}`}}>
        <Link onClick={() => handleView('consumers')}>
          <Icon><i className="fas fa-users"></i></Icon>Consumers
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'data usage' ? '700' : ''}`}}>
        <Link onClick={() => handleView('data usage')}>
          <Icon><i className="fas fa-chart-line"></i></Icon>Data Usage / Revenue
        </Link>
      </Row>
      <Row>
        <NavTitle>Audit</NavTitle>
      </Row>
      <Row style={{fontWeight: `${view === 'consumer consents' ? '700' : ''}`}}>
        <Link onClick={() => handleView('consumer consents')}>
          <Icon><i className="fas fa-user-check"></i></Icon>Consumer Consents
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'merchant consents' ? '700' : ''}`}}>
        <Link onClick={() => handleView('merchant consents')}>
          <Icon><i className="fas fa-edit"></i></Icon>Merchant Consents
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'use cases' ? '700' : ''}`}}>
        <Link onClick={() => handleView('use cases')}>
          <Icon><i className="fas fa-chart-bar"></i></Icon>Data Subscriber Use Cases
        </Link>
      </Row>
    </Container>
  );
}

export default SideNav;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  min-width: 15rem;
  width: 17rem;
  background-color: ${({ theme }) => theme.colors.primary };
  color: #fff;
  padding: 2rem 1rem;
`

const Row = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1rem;
`

const Link = styled.span`
  cursor: pointer;
  display: flex;
`

const Icon = styled.div`
  width: 2.5rem;
  margin-right: 0;
  margin-left: .5rem;
`

const NavTitle = styled.div`
  font-weight: 700;
  margin-top: 1rem;
`