import React, { useState, useCallback } from 'react';
import { setToken, decipherToken } from '../services/login';
import styled from 'styled-components';
import { getBaseURL } from '../services/utils';

const SwitchEnvironment = ({ 
    setMessage, message, setUserType, environment, setSwitchingEnvironment,
    setOpenLogin, setAlert, fetchSubscriberData, fetchPersons, handleLogout, setEnvironment
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = useCallback(async(e) => {
    setMessage('');
    e.preventDefault();
    const creds = { email, password }
    const newEnvironment = environment === 0 ? 1 : 0;
    try {
      const res = await fetch(getBaseURL(newEnvironment) + '/login', {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json'}),
        body: JSON.stringify(creds)
      })
      if (res.status === 200) {
        await handleLogout();
        setSwitchingEnvironment(true);
        const { token, refresh_token } = await res.json();
        const tokenInfo = await decipherToken(token);
        const userType = tokenInfo.type;
        setToken({ token, refresh_token});
        setEnvironment(newEnvironment);
        setUserType(userType);
        fetchSubscriberData({ token, environment: newEnvironment });
        fetchPersons({ token, environment: newEnvironment });
        setOpenLogin(false);
      } else {
        const { message } = await res.json();
        if (message) {
            setMessage(message);
        }
      }
    } catch(e) {
      console.log('Error switching environment', e)
      setAlert({ type: 1 });
    }
  }, [email, password, setMessage, setUserType, environment, handleLogout, setEnvironment,
    setAlert, fetchPersons, fetchSubscriberData, setOpenLogin, setSwitchingEnvironment])

  return (
    <Container>
        <LoginForm>
            <TitleText>
                Log in with
                {environment === 0 ? <Span> production </Span> : <Span> sandbox </Span>} 
                credentials to switch mode.
            </TitleText>
            <Form>
                <Input 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    name='email' type='email' 
                    placeholder='Email' 
                    autoComplete='current-email' />
                <Input 
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                    name='password' type='password' 
                    placeholder='Password' 
                    autoComplete='current-password' 
                />
                <Message>{message}</Message>
                <Row>
                    <Button onClick={() => {setOpenLogin(false); setMessage('')}}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </Row>
            </Form>
        </LoginForm>
    </Container>
  );
}

export default SwitchEnvironment;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border-radius: 3px;
  padding: 1rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: .9em;
  height: 2rem;
  padding: 0.3rem 0.4rem;
  width: 6rem;
  margin: 0 1rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  font-size: .8em;
  color: red;
  margin-bottom: 1rem;
  height: 1rem;
`

const Span = styled.span`
  color: ${({ theme }) => theme.colors.pentaPrimary };
`