import React from 'react';
import Overview from './Overview';
import DataSubscribers from './DataSubscribers';
import Consumers from './Consumers';
import DataUsage from './DataUsage';
import ConsumerConsents from './ConsumerConsents';
import MerchantConsents from './MerchantConsents';
import UseCases from './UseCases';
import Support from './Support';
import Faqs from './Faqs';
import '../consumer/DataTable.css'

const ContributorDashboard = ({ view, dashboardData, handleView }) => {
  
  switch(view) {

    case 'overview':
      return(
        <Overview dashboardData={dashboardData} handleView={handleView}/>
      )

    case 'data subscribers':
      return(
        <DataSubscribers dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'consumers':
      return(
        <Consumers dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'data usage':
      return(
        <DataUsage dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'consumer consents':
      return(
        <ConsumerConsents dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'merchant consents':
      return(
        <MerchantConsents dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'use cases':
      return(
        <UseCases dashboardData={dashboardData} view={view} handleView={handleView}/>
      )

    case 'support':
      return(
        <Support view={view} handleView={handleView} />
      )

    case 'faqs':
      return(
        <Faqs view={view} handleView={handleView} />
      )
    
    default: return null
  }

}

export default ContributorDashboard;
