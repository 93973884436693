import React from 'react'
import styled from 'styled-components'

export default function SourceConsent({ contributor, subscriber }) {

    // const openUrl = (url) => window.open(url, '_blank')
    
    switch(contributor) {
        case 'finicity':
            return(
                <Text>
                    <Title>{subscriber} Needs Your Consent</Title>
                    By clicking “I accept – Continue” you agree to {subscriber}'s Terms and you have read and acknowledge their Privacy Statement.
                    <br/><br/>
                    We use Pentadata, Inc., a secure, SOC2-compliant API that works with, and is trusted by, all major banks, data aggregators (including Akoya and Finicity), credit bureaus and several merchant processors (including Square, Stripe and Clover). Pentadata will never share your data without your explicit consent. By clicking “I accept – Continue”, you also agree to their Terms and have read and acknowledge their Privacy Policy.
                </Text>
            )
        case 'akoya':
            return(
                <Text>
                    <Title>{subscriber} Needs Your Consent</Title>
                    PENTADATA AND ITS LICENSORS, SERVICE PROVIDERS, AND DATA PROVIDERS DO NOT GUARANTEE OR WARRANT ANY ACCOUNT DATA’S ACCURACY, TIMELINESS, COMPLETENESS, CURRENTNESS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OR THAT IT SHALL BE ERROR-FREE.  ALL ACCOUNT DATA IS USED AND PROVIDED “AS IS”.  YOU ASSUME ALL RISKS ASSOCIATED WITH YOUR USE OF OR INABILITY TO USE THE PENTADATA SERVICE OR ANY ACCOUNT DATA.  IT IS YOUR SOLE RESPONSIBILITY TO DETERMINE THE SUITABILITY AND ADEQUACY OF THE  PENTADATA SERVICE AND ACCOUNT DATA.
                    <br/><br/>
                    Pentadata uses Akoya LLC (Akoya) to securely exchange information that your financial services providers possess about you and your financial accounts (Account Data).  Akoya shall transmit to Pentadata, Inc. your Account Data that you have authorized your financial service providers to send to Pentadata, Inc.
                    <br/><br/>
                    YOU HEREBY AUTHORIZE AKOYA TO ACCESS YOUR ACCOUNT INFORMATION MADE AVAILABLE BY YOUR FINANCIAL SERVICES PROVIDERS AND OTHER THIRD PARTIES, AS YOUR AGENT AND ON YOUR BEHALF, TO RETRIEVE, PROCESS, AND TRANSMIT TO PENTADATA YOUR ACCOUNT DATA HELD BY SUCH PARTIES.
                    <br/><br/>
                    YOU AGREE THAT NONE OF PENTADATA, AKOYA, ANY DATA PROVIDER, OR ANY OF THEIR RESPECTIVE AFFILIATES SHALL BE LIABLE FOR ANY DIRECT OR INDIRECT DAMAGES RESULTING FROM THE USE BY ANY SUCH PARTIES OF, OR INABILITY OF SUCH PARTIES TO USE, YOUR ACCOUNT DATA.
                </Text>
            )
        case 'yodlee':
            return(
                <Text>
                    <Title>{subscriber} Needs Your Consent</Title>
                    By clicking “I accept – Continue” you agree to {subscriber}'s Terms and you have read and acknowledge their Privacy Statement.
                    <br/><br/>
                    We use Pentadata, Inc., a secure, SOC2-compliant API that works with, and is trusted by, all major banks, data aggregators (including Akoya and Finicity), credit bureaus and several merchant processors (including Square, Stripe and Clover). Pentadata will never share your data without your explicit consent. By clicking “I accept – Continue”, you also agree to their Terms and have read and acknowledge their Privacy Policy.
                </Text>
            )
        case 'mx':
            return(
                <Text>
                    <Title>{subscriber} Needs Your Consent</Title>
                    By clicking “I accept – Continue” you agree to {subscriber}'s Terms and you have read and acknowledge their Privacy Statement.
                    <br/><br/>
                    We use Pentadata, Inc., a secure, SOC2-compliant API that works with, and is trusted by, all major banks, data aggregators (including Akoya and Finicity), credit bureaus and several merchant processors (including Square, Stripe and Clover). Pentadata will never share your data without your explicit consent. By clicking “I accept – Continue”, you also agree to their Terms and have read and acknowledge their Privacy Policy.
                </Text>
            )
        default:
            return null
    }
}


// const Span = styled.span`
//     color: ${({ theme }) => theme.colors.primary };
//     font-size: 1em;
//     cursor: pointer;
// `

const Text = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-size: 1em;
  margin: 1rem;
  text-align: justify;
  max-width: 22rem;
  max-height: 28rem;
  overflow: scroll;

  @media (max-width: 414px) {
    width: 17rem;
  }
`

const Title = styled.div`
  font-size: 1.4em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
  margin-bottom: .5rem;
`
