import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Toggle from '../common/ToggleSwitch';
import Modal from 'react-modal';
import { modalStyles } from '../../Theme';

Modal.setAppElement("#root");

const UseCases = ({ view, handleView }) => {
  const [openModal, setOpenModal] = useState(false);

  const dashboardData = {finance: true, offers: false, loyalty: true, target: false, attribution: false}

  const handleToggle = useCallback(() => {
    setOpenModal(true);
  }, [])

  const dataTable = dashboardData
  ? <table>
       <thead>
         <tr>
           <th>Data Source</th>
           <StyledTh>Active</StyledTh>
         </tr>
       </thead>
       <tbody>
          <StyledTr>
            <td>Personal Finance Management</td>
            <StyledTd>
              <Toggle 
                id={'toggle-finance'}
                isOn={dashboardData.finance} 
                handleToggle={handleToggle}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Card-linked Offers</td>
            <StyledTd>
              <Toggle 
                id={'toggle-card-linked-offers'}
                isOn={dashboardData.offers} 
                handleToggle={handleToggle}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Card-linked Loyalty</td>
            <StyledTd>
              <Toggle 
                id={'toggle-card-linked-loyalty'}
                isOn={dashboardData.loyalty} 
                handleToggle={handleToggle}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Targeting</td>
            <StyledTd>
              <Toggle 
                id={'toggle-targeting'}
                isOn={dashboardData.target} 
                handleToggle={handleToggle}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Offline Attribution</td>
            <StyledTd>
              <Toggle 
                id={'toggle-offline-attribution'}
                isOn={dashboardData.attribution} 
                handleToggle={handleToggle}
              />
            </StyledTd>
          </StyledTr>
       </tbody>
     </table>
   : <NoDataTitle>No data available</NoDataTitle>

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
      <Title>Data Subscriber Dashboard</Title>
      <Row>
        <Card>
          <CardTitle><Icon><i className="fas fa-code"></i></Icon>Your Use Cases</CardTitle>
          <TableContainer>
            <Row>
              {dataTable}
            </Row>
          </TableContainer>
        </Card>
      </Row>
      </Content>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalMessage style={{maxWidth: '21rem'}}>
            Please contact customer service at &nbsp;
            <Link onClick={() => window.open("mailto:customersupport@pentadatainc.com", "_blank")}> 
              customersupport@pentadatainc.com 
            </Link>, &nbsp;
            or use our contact form.
          </ModalMessage>
          <Row>
            <ModalButton 
              style={{width: 'auto'}}
              onClick={() => {setOpenModal(false); handleView('subscriber support')} }
            >
              Contact
            </ModalButton>
            <ModalButton onClick={() => setOpenModal(false)}>Close</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default UseCases;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  margin: 0rem 4rem 1rem 4rem;
  padding: .2rem;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  width: 50%;
  margin: 3rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
  margin-bottom: 1rem;
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
`

const TableContainer = styled.div`
  margin-top: 2rem;
`

const StyledTd = styled.td`
  display: flex;
  justify-content:center;
  align-items: center;
  margin: auto;
`

const StyledTh = styled.th`
  text-align: center;
`

const StyledTr = styled.tr`
  td {
    color: ${({ theme }) => theme.colors.textSecondary }
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: 5rem;
  height: 2.25rem;
  margin: .5rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const Link = styled.a`
  cursor: pointer;
`