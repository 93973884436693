import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
// import { login } from '../services/login';
import styled from 'styled-components';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('');
  const [showVerify, setShowVerify] = useState(false);

  const handleSubmit = useCallback(async(e) => {
    setShowVerify(true); //if response is good
    //setMessage('') if bad
    // e.preventDefault();
    // const creds = { email, password, password2 }
    // try {
    //   const signupResponse = await login(creds);
    //   setShowVerify(true);
    // } catch {
    //   setMessage('There was a problem with your request. Please contact customer service.')
    // }
  }, [])

  const isFormValid = useCallback(() => {
    // return !(email && password && password === passwordConfirm);
    if (email && password && password2) {
      if (password === password2) {
        handleSubmit();
      } else {
        setMessage('Passwords do not match')
      }
    } else {
      setMessage('Form incomplete');
    }
  }, [email, password, password2, handleSubmit])

//  const signUpUrl = () => { window.open('https://www.pentadatainc.com', '_blank') }

const signupView = showVerify
  ? <LoginForm style={{width: '23rem'}}>
      <Title>Pentadata</Title>
      <Text>Email has been sent to {email}.</Text>
      <Text>Please follow instructions in your email to complete sign up.</Text>
      <Link to="/"><Button style={{width: '7rem', fontSize: '1.3em', marginBottom: '1rem'}}>LOG IN</Button></Link>
    </LoginForm>
  : <LoginForm>
      <Title>Pentadata</Title>
      <TitleText>SIGN UP</TitleText>
        <Form>
          <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='email' />
          <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='new-password' />
          <Input value={password2} onChange={e => setPassword2(e.target.value)} name='password confirm' type='password' placeholder='Confirm Password' autoComplete='confirm-password' /> 
        </Form>
        {/* <ForgotLink><span onClick={signUpUrl} className='link'>Forgot password?</span></ForgotLink> */}
        <Button onClick={isFormValid} >Submit</Button>
        <Row>
          <Message>{message}</Message>
        </Row>
      {/* <SignUpLink>Not yet a member? <span onClick={signUpUrl} className='link'>Sign Up</span></SignUpLink> */}
    </LoginForm>


  return (
    <Container>
      {signupView}
    </Container>
  );
}

export default Signup;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-top: 1rem;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
`

const Title = styled.div`
  width: 100%;
  font-size: 2.4em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textSecondary };
  margin-bottom: 2rem;
`

const Text = styled.div`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text };
  margin: 1rem auto;
  width: 100%;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  color: red;
  vertical-align: center;
  height: 1rem;
  display: flex;
  align-items: center;
`