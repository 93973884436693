export const dataSubscribers = [
  {companyId: "C-217BC441", startDate: 20191027, type: "Publisher"},
  {companyId: "C-217BC442", startDate: 20191027, type: "Card Issuer"},
  {companyId: "C-217BC443", startDate: 20191102, type: "Merchant"},
  {companyId: "C-217BC444", startDate: 20190522, type: "Card Issuer"},
  {companyId: "C-217BC445", startDate: 20191010, type: "Loyalty"},
  {companyId: "C-217BC446", startDate: 20191014, type: "Publisher"},
  {companyId: "C-217BC447", startDate: 20191209, type: "Merchant"},
  {companyId: "C-217BC448", startDate: 20190813, type: "Publisher"},
  {companyId: "C-217BC449", startDate: 20190320, type: "Merchant"},
  {companyId: "C-217BC450", startDate: 20190612, type: "Merchant"},
  {companyId: "C-217BC451", startDate: 20180709, type: "Publisher"},
  {companyId: "C-217BC452", startDate: 20190903, type: "Merchant"},
  {companyId: "C-217BC453", startDate: 20190412, type: "Card Issuer"},
  {companyId: "C-217BC454", startDate: 20190427, type: "Merchant"},
  {companyId: "C-217BC455", startDate: 20190204, type: "Merchant"},
  {companyId: "C-217BC456", startDate: 20190206, type: "Merchant"},
  {companyId: "C-217BC457", startDate: 20200109, type: "Merchant"},
  {companyId: "C-217BC458", startDate: 20200108, type: "Loyalty"},
]

export const consumers = [
  {
    consumerId: 'C-217BC441',
    transactions: [
      {date: 20200113, transactionId: 1112, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1113, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1114, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1115, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1116, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC442',
    transactions: [
      {date: 20200113, transactionId: 1117, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1118, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1119, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1110, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1122, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC443',
    transactions: [
      {date: 20200113, transactionId: 1133, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1144, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1155, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1166, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1177, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC444',
    transactions: [
      {date: 20200113, transactionId: 1188, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1199, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1211, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1311, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1411, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC445',
    transactions: [
      {date: 20200113, transactionId: 1222, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1223, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1224, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1225, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1226, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC446',
    transactions: [
      {date: 20200113, transactionId: 1227, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1228, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 1229, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1333, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 1334, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC447',
    transactions: [
      {date: 20200113, transactionId: 2211, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 3111, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 4111, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 5111, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 6111, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC448',
    transactions: [
      {date: 20200113, transactionId: 7111, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 8111, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 9111, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 1011, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 2311, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC449',
    transactions: [
      {date: 20200113, transactionId: 3311, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 3411, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 3511, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 3611, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 3711, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
  {
    consumerId: 'C-217BC450',
    transactions: [
      {date: 20200113, transactionId: 1881, amount: 36.21, merchant: 'Amazon.com', account: 4378},
      {date: 20200114, transactionId: 1871, amount: 9.15, merchant: 'BP', account: 2894},
      {date: 20200120, transactionId: 3131, amount: 354.17, merchant: 'Whole Foods', account: 2209},
      {date: 20200122, transactionId: 3121, amount: 12.11, merchant: 'CVS', account: 1423},
      {date: 20200126, transactionId: 9991, amount: 24.99, merchant: 'Williams Dry Cleaners', account: 9756},
    ]
  },
]

export const dataUsage = [
  {company: "Amazon", apiCalls: 453, revenue: 474.49, paymentDate: 20200301},
  {company: "Best Buy", apiCalls: 453, revenue: 474.49, paymentDate: 20200329},
  {company: "CVS", apiCalls: 3312, revenue: 7211.03, paymentDate: 20200101},
  {company: "Gas Buddy", apiCalls: 536, revenue: 577.28, paymentDate: 20200401},
  {company: "Harvey Nichols", apiCalls: 3313, revenue: 7393.95, paymentDate: 20200105},
  {company: "Hilton Worldwide", apiCalls: 2143, revenue: 6303.94, paymentDate: 20200530},
  {company: "Kohl's", apiCalls: 939, revenue: 995.11, paymentDate: 20200701},
  {company: "Microsoft", apiCalls: 1209, revenue: 3003.33, paymentDate: 20200323},
  {company: "Outback Steakhouse", apiCalls: 8221, revenue: 703.31, paymentDate: 20200613},
  {company: "PetSmart", apiCalls: 809, revenue: 2503.36, paymentDate: 20200123},
  {company: "Rakuten", apiCalls: 11109, revenue: 4303.13, paymentDate: 20200320},
  {company: "Target", apiCalls: 805, revenue: 1993.32, paymentDate: 20200313},
  {company: "Shell", apiCalls: 209, revenue: 1003.33, paymentDate: 20200823},
  {company: "Total Wine and More", apiCalls: 509, revenue: 2003.53, paymentDate: 20200823},
  {company: "Trader Joe's", apiCalls: 889, revenue: 703.11, paymentDate: 20200421},
  {company: "Walmart", apiCalls: 1109, revenue: 7451.18, paymentDate: 20201023},
  {company: "Whole Foods", apiCalls: 789, revenue: 5003.45, paymentDate: 20201223},
  {company: "World Market", apiCalls: 489, revenue: 993.79, paymentDate: 20200928},
]

export const consumerConsents = [
  {consumerId: "C-217BC441", optInDate: 20200107 , optOutDate: null, optInForm: "Paper", optInSource: "Rakuten"},
  {consumerId: "C-217BC442", optInDate: 20200108 , optOutDate: null, optInForm: "Paper", optInSource: "City Market"},
  {consumerId: "C-217BC443", optInDate: 20200322 , optOutDate: null, optInForm: "Paper", optInSource: "CVS"},
  {consumerId: "C-217BC444", optInDate: 20200323 , optOutDate: null, optInForm: "Mobile", optInSource: "PetSmart"},
  {consumerId: "C-217BC445", optInDate: 20200402 , optOutDate: null, optInForm: "Mobile", optInSource: "Target"},
  {consumerId: "C-217BC446", optInDate: 20200723 , optOutDate: null, optInForm: "Web", optInSource: "Home Depot"},
  {consumerId: "C-217BC447", optInDate: 20201001 , optOutDate: null, optInForm: "Web", optInSource: "World Market"},
  {consumerId: "C-217BC448", optInDate: 20200414 , optOutDate: null, optInForm: "Paper", optInSource: "Publix"},
  {consumerId: "C-217BC449", optInDate: 20200123 , optOutDate: 20200707, optInForm: "Mobile", optInSource: "Microsoft"},
  {consumerId: "C-217BC500", optInDate: 20200202 , optOutDate: null, optInForm: "Web", optInSource: "Total Wine and More"},
  {consumerId: "C-217BC501", optInDate: 20200804 , optOutDate: null, optInForm: "Mobile", optInSource: "Whole Foods"},
  {consumerId: "C-217BC502", optInDate: 20201208 , optOutDate: null, optInForm: "Web", optInSource: "Walmart"},
]

export const merchantConsents = [
  {merchant: "CVS", optInDate: 20200107 , optOutDate: 20210103, optInSource: "TransUnion"},
  {merchant: "Harvey Nichols", optInDate: 20200108 , optOutDate: 20210104, optInSource: "Groupon"},
  {merchant: "Nordstorm", optInDate: 20200322 , optOutDate: 20210105, optInSource: "Yelp"},
  {merchant: "Outback Steakhouse", optInDate: 20200323 , optOutDate: 20210106, optInSource: "Meniga"},
  {merchant: "Rakuten", optInDate: 20200402 , optOutDate: 20210107, optInSource: "Rakuten CLO"},
  {merchant: "Sephora", optInDate: 20200723 , optOutDate: 20210108, optInSource: "Collinson"},
  {merchant: "Shell", optInDate: 20201001 , optOutDate: 20210109, optInSource: "Discover"},
  {merchant: "Target", optInDate: 20200414 , optOutDate: 20210110, optInSource: "Gas Buddy"},
  {merchant: "Total Wine and More", optInDate: 20200123 , optOutDate: 20200711, optInSource: "Microsoft"},
  {merchant: "Trader Joe's", optInDate: 20200202 , optOutDate: 20210130, optInSource: "Augeo"},
  {merchant: "Whole Foods", optInDate: 20200804 , optOutDate: 2021023, optInSource: "Google"},
  {merchant: "World Market", optInDate: 20201208 , optOutDate: 20210114, optInSource: "Mastercard"},
]

export const dataSubscriberUseCases = [
  {merchant: "CVS", date: 20200107, useCases: ["Re-targeting"]},
  {merchant: "Harvey Nichols", date: 20200108, useCases: ["CLO"]},
  {merchant: "Nordstorm", date: 20200322 ,useCases: ["CLO"]},
  {merchant: "Outback Steakhouse", date: 20200323 ,useCases: ["Targeting"]},
  {merchant: "Rakuten", date: 20200402 ,useCases: ["CLO", "Campaign Measurement", "Targeting"]},
  {merchant: "Sephora", date: 20200723 ,useCases: ["Campaign Measurement"]},
  {merchant: "Shell", date: 20201001 ,useCases: ["Campaign Measurement"]},
  {merchant: "Target", date: 20200414 ,useCases: ["Re-targeting", "Loyalty", "CLO"]},
  {merchant: "Total Wine and More", date: 20200123 ,useCases: ["Re-targeting", "Loyalty", "CLO"]},
  {merchant: "Trader Joe's", date: 20200202 ,useCases: ["Re-targeting"]},
  {merchant: "Whole Foods", date: 20200804 ,useCases: ["Loyalty"]},
  {merchant: "World Market", date: 20201208 ,useCases: ["Targeting"]},
]