import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheck } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import Loading from 'react-loading';
import isLoading from '../common/isLoading';
import { BASE_URL, formatTUDate,  } from '../../services/utils';
import { modalStylesO, modalStyles } from '../../Theme';
import AuthForm from './AuthForm';
import { v4 as uuidv4 } from 'uuid';
import 'react-phone-number-input/style.css'

const Profile = ({
    dashboardData,
    setLoading,
    getToken,
    setMessage,
    setAlert,
    fetchDashboardData,
    sendSlack
}) => {

    const history = useHistory();

    const checkToken = async() => {
        const { token } = await getToken();
        if (!token) history.push('/consumers/login')
    }

    // Authentication
    const [openAuth, setOpenAuth] = useState(false);
    const [openQuestions, setOpenQuestions] = useState(true);
    const [questions, setQuestions] = useState(null);
    const [address, setAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [addrState, setAddrState] = useState(null);
    const [country, setCountry] = useState(null);
    const [dob, setDob] = useState(null);
    const [authNonce, setAuthNonce] = useState(null);
    const [answers, setAnswers] = useState({});
    const [firstNameSt, setFirstNameSt] = useState(null);
    const [lastNameSt, setLastNameSt] = useState(null);

    const [helpText, setHelpText] = useState('');
    const [openSlack, setOpenSlack] = useState(false);

    useEffect(() => {
        checkToken();
    })

    useEffect(() => {
        if (!authNonce) {
            setAuthNonce(uuidv4());
        }
    }, [authNonce])

    const formatAnswers = (answers) => {
        let formattedAnswers = [];
        for (const p in answers) {
            formattedAnswers.push({
                questionId: p,
                answerId: answers[p]
            })
        }
        return formattedAnswers;
    };

    const handleAnswer = useCallback((e) => {
        let tmp = answers;
        const questionId = e.target.name;
        const answerId = e.target.value;
        tmp[questionId] = answerId;
        setAnswers(tmp);
    }, [answers])

    const submitAnswers = async ({ ssn, phone }) => {
        setLoading(true);
        const { token } = await getToken();
        if (token) {
            try {
                const res = await fetch(BASE_URL + '/consumers/verify', {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }),
                    body: JSON.stringify({
                        answers: formatAnswers(answers),
                        fulfillmentKey: questions.fulfillmentKey,
                        authNonce,
                        dob: formatTUDate(dob),
                        firstName: firstNameSt,
                        lastName: lastNameSt,
                        address,
                        city,
                        state: addrState,
                        postalCode
                    })
                })
                switch (res.status) {
                    case 200:
                        const data = await res.json();
                        setOpenQuestions(false);
                        if (!data) return null;
                        if (data.questions) {
                            setQuestions(prevState => {
                                return ({
                                    ...prevState,
                                    questions: data.questions
                                })
                            });
                            setOpenAuth(false);
                            setOpenQuestions(true);
                        }
                        setAnswers({});
                        break;
                    case 202:
                        setOpenQuestions(false);
                        setMessage('Thank you, your account has been verified.');
                        setAlert({ type: 0 });
                        fetchDashboardData({ token });
                        break;
                    case 203:
                        setOpenQuestions(false);
                        setMessage('Thank you, your account has been verified'); // no credit report needed
                        setAlert({ type: 0 });
                        fetchDashboardData({ token });
                        break;
                    case 401:
                        setHelpText(`Hi, I cannot verify my account information. Please reach out to help me. \n\nBest, \n${dashboardData.first_name} \n${dashboardData.email}`);
                        setOpenSlack(true);
                        break;
                    default:
                        setOpenQuestions(false);
                        setMessage('We were not able to authenticate you at this time.');
                        setAlert({ type: 0 });
                }
                setLoading(false);
            }
            catch (e) {
                setLoading(false);
                setAlert({ type: 1 });
                setOpenQuestions(false);
                setAnswers({});
                console.log("Error with verification request", e)
            }
        }
    }

    const submitAuthentication = useCallback(async({ ssn, phone, firstName, lastName }) => {
        setLoading(true);
        const { token } = await getToken();
        if (!token) return;
        try {
            const res = await fetch(BASE_URL + '/consumers/authenticate', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }),
                body: JSON.stringify({
                    // firstName: profileData.firstName,
                    // lastName: profileData.lastName,
                    firstName,
                    lastName,
                    ssn,
                    postalCode,
                    city,
                    address,
                    state: addrState,
                    phone,
                    country,
                    dob: formatTUDate(dob),
                    authNonce
                })
            })
            const data = await res.json();
            setAnswers({});
            if (res.status === 200) {
                if (data.questions) {
                    setQuestions(data);
                    setOpenAuth(false);
                    setOpenQuestions(true);
                } else {
                    setMessage('Not able to verify.');
                    setAlert({ type: 0 });
                }
            } else if (res.status === 401) {
                setMessage('Not able to verify.');
                setAlert({ type: 0 });
                setOpenAuth(false);
            } else {
                setMessage('We were not able to authenticate you at this time.');
                setAlert({ type: 0 });
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            setAlert({ type: 1 });
            console.log("Error with authentication request", e)
        }
    }, [setMessage, setLoading, getToken, setAlert, authNonce, dob, address, addrState, postalCode, city, country])

    const showAuth = openAuth &&
        <Modal
            isOpen={openAuth}
            style={modalStylesO}
            contentLabel="Form"
            overlayClassName="modal-overlay"
        >
            <ModalContent>
                <Message style={{ fontSize: '1.2em', margin: '1rem' }}><strong>Authenticate</strong></Message>
                <AuthForm
                    setAlert={setAlert}
                    setMessage={setMessage}
                    setOpenAuth={setOpenAuth}
                    dashboardData={dashboardData}
                    submitAuthentication={submitAuthentication}
                    dob={dob}
                    setDob={setDob}
                    questions={questions}
                    setQuestions={setQuestions}
                    address={address}
                    setAddress={setAddress}
                    postalCode={postalCode}
                    city={city}
                    setCity={setCity}
                    setPostalCode={setPostalCode}
                    authNonce={authNonce}
                    country={country}
                    setCountry={setCountry}
                    addrState={addrState}
                    setAddrState={setAddrState}
                    setFirstNameSt={setFirstNameSt}
                    setLastNameSt={setLastNameSt}
                />
            </ModalContent>
        </Modal>

    const showQuestions = questions &&
        <Modal
            isOpen={openQuestions}
            style={modalStyles}
            contentLabel="Form"
            overlayClassName="modal-overlay"
        >
            <ModalContent style={{ width: '24rem' }}>
                <Message style={{ fontSize: '1.2em', margin: '1rem' }}><strong>Authentication Questions</strong></Message>
                <Message style={{ marginBottom: '1rem' }}>Please answer these questions to verify your identity.</Message>
                <MultipleChoice>
                    {
                        questions.questions.map((q, i) => (
                            <QuestionContainer onChange={(e) => handleAnswer(e)} key={`profile-question-${q.questionId + i}`}>
                                <Label style={{ marginLeft: '1rem' }}>{q.fullQuestionText}</Label>
                                {q.answerChoice.map((a, i) => (
                                    <Row style={{ margin: '0', marginLeft: '2rem' }} key={`profile-answer-${a.answerId + i}`}>
                                        <Radio type="radio" value={a.answerId} name={q.questionId} /><Answer>{a.answerChoiceText}</Answer>
                                    </Row>
                                ))}
                            </QuestionContainer>
                        ))
                    }
                </MultipleChoice>
                <Row>
                    <Button onClick={() => setOpenQuestions(false)} style={{ marginRight: '2rem', width: '7rem' }}>Cancel</Button>
                    <Button onClick={submitAnswers} style={{ width: '7rem' }}>Submit</Button>
                </Row>
            </ModalContent>
        </Modal>

    const showMessageModal = openSlack &&
        <Modal
            isOpen={openSlack}
            style={modalStyles}
            contentLabel="Form"
            overlayClassName="modal-overlay"
        >
            <ModalContent>
                <Message style={{ fontSize: '1.1em', marginTop: '1rem', marginBottom: '1rem' }}><strong>Send request for help.</strong></Message>
                <TextInput required value={helpText} onChange={(e) => setHelpText(e.target.value)}
                    name='text' type='text' placeholder='' autoComplete='text' />
                <Row>
                    <Button style={{ width: 'auto' }} onClick={() => setOpenSlack(false)}>Cancel</Button>
                    <Button style={{ width: 'auto' }} onClick={() => { sendSlack(helpText); setOpenSlack(false) }}>Send</Button>
                </Row>
            </ModalContent>
        </Modal>

    if (!dashboardData) {
        return (
            <LoadingContainer>
                <Loading type={'spin'} color={'#424242'} height={30} width={30} />
            </LoadingContainer>
        )
    }

    return (
        <Content>
            <CardTitle><Icon><FontAwesomeIcon icon={faUser} /></Icon>Privacy Control</CardTitle>
            <ProfileRow>
                <ProfileColumn>
                    <ProfileLabel>NAME:</ProfileLabel>
                    <ProfileText>{`${dashboardData.first_name} ${dashboardData.last_name}`}</ProfileText>
                </ProfileColumn>
                <ProfileColumn>
                    <ProfileLabel>EMAIL:</ProfileLabel>
                    <ProfileText>{dashboardData.email}</ProfileText>
                </ProfileColumn>
                <ProfileColumn>
                    <ProfileLabel>AUTHENTICATION STATUS:</ProfileLabel>
                    {dashboardData.verification?.tu_verified
                        ? <ProfileText>
                            Verified
                            <Check><FontAwesomeIcon icon={faCheck} /></Check>
                        </ProfileText>
                        : <Button
                            style={{ margin: '.5rem auto auto 0' }}
                            onClick={() => setOpenAuth(true)}
                        >
                            Authenticate
                        </Button>
                    }
                </ProfileColumn>
            </ProfileRow>
            <ProfileRow style={{ minHeight: '4rem' }}>
                <ProfileColumn>
                    <ProfileLabel>ADDRESS:</ProfileLabel>
                    <ProfileText>
                        {dashboardData.address ? dashboardData.address : ''}
                    </ProfileText>
                </ProfileColumn>
                <ProfileColumn>
                    <ProfileLabel>PHONE:</ProfileLabel>
                    <ProfileText>{dashboardData.phone ? dashboardData.phone : ''}</ProfileText>
                </ProfileColumn>
            </ProfileRow>
            {showAuth}
            {showQuestions}
            {showMessageModal}
        </Content>
    );
}

export default isLoading(Profile);


const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  width: auto;
  padding: 3rem 3rem 0 3rem;
  ${({ theme }) => theme.animations.fadeIn};
  font-size: 1em;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 100vh;
`

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2rem;
  min-width: 5rem;
  font-size: 1em;
  margin: 1rem 1rem 0 1rem;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  };

  i {
    margin-right: .5rem;
  }
`

const ProfileRow = styled.div`
  display: flex;
  margin: 1.5rem 0 0 0;
  min-height: 3rem;
  padding-left: 1rem;
  @media (max-width: 414px) {
    margin-left: 3rem;
  }
`

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14rem;

  @media (max-width: 768px) {
    min-width: 10rem;
  }

`

const ProfileLabel = styled.label`
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.textSecondary};
`

const ProfileText = styled.label`
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.text};
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  text-align: center;
  max-width: 19rem;
  margin: auto 1rem;

  @media (max-width: 414px) {
    max-width: 15rem
    width: 100%;
  }
`

const Check = styled.span`
  padding: .3rem auto;
  margin: auto auto auto .5rem;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.primary};
`

const Radio = styled.input`
  margin: .5rem;
  height: auto;
  padding: .5rem;
  box-shadow: none;
  :focus {
    box-shadow: none;
  }
`

const Answer = styled.span`
  font-size: .9em;
`

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const QuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`

const MultipleChoice = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-height: 30rem;
`

const Label = styled.label`
  font-size: 1em;
`

const TextInput = styled.textarea`
  height: 10.5rem;
  margin-bottom: 1rem;
  resize: vertical;
  font-size: 1.2em;
  @media (max-width: 414px) {
    max-width: 90%;
  }
`
