import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Profile from './Profile';
import Accounts from './Accounts';
import Tracking from './Tracking';
import './DataTable.css';

const Privacy = ({
    dashboardData,
    getToken,
    userType,
    setMessage,
    setAlert,
    fetchDashboardData,
    fetchAccountsData,
    fetchTrackingData,
    accountsData,
    trackingData,
    sendSlack,
    webToken,
    setEnvironment
}) => {

    const history = useHistory();

    const checkToken = async() => {
        if (!webToken) history.push('/consumers/login');
        if (userType !== 'consumer') history.push('/');
    };

    useEffect(() => {
        checkToken();
        setEnvironment(1);
    });

    return (
        <Container>
            <Column>
                <Profile 
                    dashboardData={dashboardData}
                    getToken={getToken}
                    setMessage={setMessage}
                    setAlert={setAlert}
                    fetchDashboardData={fetchDashboardData}
                    sendSlack={sendSlack}
                />
                <Accounts
                    accountsData={accountsData}
                    fetchAccountsData={fetchAccountsData}
                    setAlert={setAlert}
                    setMessage={setMessage}
                    getToken={getToken}
                    fetchTrackingData={fetchTrackingData}
                />
                <Tracking
                    trackingData={trackingData}
                />
            </Column>
        </Container>
    );
}

export default Privacy;

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    height: auto;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`