import React from 'react';
import styled from 'styled-components';
import SideNav from '../subscriber/SideNav';
import ContactForm from '../ContactForm';

const Support = ({ view, handleView, environment, getToken, setAlert, dashboardData }) => {
  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <CardTitle><Icon><i className="fas fa-edit"></i></Icon>Support</CardTitle>
          <CardText>
            Please use the form below if you have any questions and a representative will get back to you within 
            48 business hours. For immediate questions please email 
            <Link onClick={() => window.open("mailto:support@pentadatainc.com", "_blank")}> support@pentadatainc.com</Link>.
          </CardText>
          <ContactForm 
            environment={environment}
            handleView={handleView}
            setAlert={setAlert}
            getToken={getToken}
            dashboardData={dashboardData}
          />
        </Card>
      </Content>
    </Container>
  );
}

export default Support;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  height: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.1em;
  margin-bottom: 2rem;
`

const Link = styled.a`
  cursor: pointer;
`