import React, { useContext } from 'react';
import { BarChart, YAxis, Bar, LabelList, XAxis } from 'recharts';
import styled, { ThemeContext } from 'styled-components';

const HorizontalBar = ({ data, text, width }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container> 
      <Label>{text}</Label>
      <BarChart 
        width={width} 
        height={70} 
        data={data} 
        layout="vertical"
        margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
      >
        <XAxis type="number" hide />
        <YAxis type="category" width={100} dataKey="name" hide/>
        <Bar dataKey="value" fill={themeContext.colors.primary} >
          <LabelList dataKey="value" position="insideRight" 
            style={{ fontSize: '2em', fill: '#fff', fontWeight: '700' }}
          />
        </Bar>
      </BarChart>
    </Container>
  );
}

export default HorizontalBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const Label = styled.label`
  margin-top: .5rem;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`
