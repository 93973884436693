import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import SubscriberCircleBar from '../common/SubscriberCircleBar';
import ProgressProvider from '../common/ProgressProvider';
import { AppContext } from '../../App';

const Overview = () => {
  const [personCount, setPersonCount] = useState(null);
  const [errors, setErrors] = useState({});

  const {
    dashboardData,
    handleView,
    personData,
    accountSearch,
    setAccountSearch,
    merchantSearch,
    setMerchantSearch,
    sightSearch,
    setSightSearch
  } = useContext(AppContext);

  useEffect(() => {
    if (personData) {
      personData.total ? setPersonCount(personData.total) : setPersonCount(0);
    }
  }, [dashboardData.products, dashboardData.data_sources, personData])

  const getTotalCalls = (products) => {
    let total = 0
    const current = products.current
    if (current?.bank_login) {
      total += current.bank_login
    }
    if (current?.bank_account) {
      total += current.bank_account
    }
    if (current?.merchant_sight) {
      total += current.merchant_sight
    }
    if (current?.mcc_match) {
      total += current.mcc_match
    }
    if (current?.bank_account_ach) {
      total += current.bank_account_ach
    }
    if (current?.bank_account_owner) {
      total += current.bank_account_owner
    }
    if (current?.bank_account_balance) {
      total += current.bank_account_balance
    }
    if (current?.bank_account_balance_live) {
      total += current.bank_account_balance_live
    }
    if (current?.bank_account_statement) {
      total += current.bank_account_statement
    }
    if (current?.auth) {
      total += current.auth
    }
    if (current?.bank_login_delete) {
      total += current.bank_login_delete
    }
    if (current?.bank_account_delete) {
      total += current.bank_account_delete
    }
    return total
  }

  const showAPICalls = () => {
    if(!dashboardData) return null;
    if(!dashboardData.products) return null;
    return(
      <Number>{getTotalCalls(dashboardData.products)}</Number>
    )
  }

  const handleSearchAccounts = useCallback(async() => {
    if (!accountSearch) {
      setErrors({accounts: 'Person ID required'});
      return;
    } else {
      setErrors({});
    }
    handleView('accounts');
  }, [handleView, accountSearch])

  const handleSearchMerchant = useCallback(async() => {
    if (!merchantSearch) {
      setErrors({merchant: 'Text required'});
      return;
    } else {
      setErrors({});
    }
    handleView('merchant');
  }, [handleView, merchantSearch])

  const handleSearchSight = useCallback(async() => {
    if (!sightSearch.text) {
      setErrors({sight: 'Text required'});
      return;
    } else {
      setErrors({});
    }
    handleView('sight');
  }, [handleView, sightSearch])

  const handleSightChange = (e) => {
    e.persist()
    setSightSearch(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <Container>
      <Welcome>
        <Title>Data Subscriber Dashboard</Title>
        <Text>
          Welcome to the Pentadata Data Subscriber Dashboard. Using the tools below, you can view data usage,
          control products, modify use cases, and review data sources.
        </Text>
      </Welcome>
      <Cards>
        <Card>
          <CardTitle><Icon><i className="icon fas fa-cloud-download-alt"></i></Icon>Data Usage</CardTitle>
          <CardText>View total API calls, total cost, and plan details.</CardText>
          <Column>
            <SubTitle>API Requests</SubTitle>
            {showAPICalls()}
          </Column>
          <Row>
            <Button onClick={() => {handleView('data usage')}}>View Details</Button>
          </Row>
        </Card>

        <Card>
          <CardTitle><Icon><i className="icon fas fa-users"></i></Icon>Persons</CardTitle>
          <CardText>List all Persons enrolled in account.</CardText>
          <ProgressProvider valueStart={0} valueEnd={personCount}>
            {value => <SubscriberCircleBar value={value} total={3} text={'persons'} />}
          </ProgressProvider>
          <Row>
            <Button onClick={() => {handleView('persons')}}>View Details</Button>
          </Row>
        </Card>

        <Card>
          <CardTitle><Icon><i className="icon fas fa-money-bill-wave"></i></Icon>Pay</CardTitle>
          <CardText>Pay Your Customers.</CardText>
          <Column style={{justifyContent: 'center', alignItems: 'center', margin: '0'}}>
          <Icon><i style={{fontSize:"5rem"}} className="icon fas fa-users"></i></Icon>
          </Column>
          <Row>
            <Button onClick={() => {handleView('pay')}}>View Details</Button>
          </Row>
        </Card>

        <Card>
          <CardTitle><Icon><i className="icon fas fa-university"></i></Icon>Accounts/Cards</CardTitle>
          <CardText>List all accounts and cards from a Person.</CardText>
          <Column style={{justifyContent: 'center', alignItems: 'center', margin: '0'}}>
            <Input 
              type="number" 
              onChange={(e) => setAccountSearch(e.target.value)}
              placeholder="Person ID"
              value={accountSearch}
            />
            <ErrorText>{errors.accounts}</ErrorText>
          </Column>
          <Row style={{ marginBottom: '0', marginTop: 'auto'}}>
            <Button onClick={handleSearchAccounts}>Search <ButtonIcon className="fas fa-search"></ButtonIcon></Button>
          </Row>
        </Card>

        <Card>
          <CardTitle><Icon><i className="icon fas fa-receipt"></i></Icon>MccMatch</CardTitle>
          <CardText>Get MCC codes by inputting text from transactions.</CardText>
          <Column style={{justifyContent: 'center', alignItems: 'center', margin: '0'}}>
            <Input 
              onChange={(e) => setMerchantSearch(e.target.value)}
              placeholder="Search.."
              value={merchantSearch}
            />
            <ErrorText>{errors.merchant}</ErrorText>
          </Column>
          <Row style={{ marginBottom: '0', marginTop: 'auto'}}>
            <Button onClick={handleSearchMerchant}>Search <ButtonIcon className="fas fa-search"></ButtonIcon></Button>
          </Row>
        </Card>

        <Card>
          <CardTitle><Icon><i className="icon fas fa-eye"></i></Icon>MerchantSight</CardTitle>
          <CardText>Identify merchant using text from statements. Include City and Postal if available.</CardText>
          <Column style={{justifyContent: 'center', alignItems: 'flex-start', margin: '0'}}>
            <Input 
              onChange={handleSightChange}
              name='text'
              placeholder='Search..'
              value={sightSearch.text}
              style={{ width: '18rem'}}
            />
            <Row style={{ width: '100%', justifyContent: 'flex-start', marginTop: '1rem' }}>
              <Input 
                onChange={handleSightChange}
                name= 'city'
                placeholder='City'
                value={sightSearch.city}
                style={{ width: '5rem', marginRight: '1rem'}}
              />
              <Input 
                onChange={handleSightChange}
                name='postal'
                placeholder='Postal'
                value={sightSearch.postal}
                style={{ width: '5rem'}}
              />
            </Row>
            <ErrorText>{errors.sight}</ErrorText>
          </Column>
          <Row style={{ marginBottom: '0', marginTop: 'auto'}}>
            <Button onClick={handleSearchSight}>Search <ButtonIcon className="fas fa-search"></ButtonIcon></Button>
          </Row>
        </Card>

      </Cards>
    </Container>
  );
}

export default Overview;

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
`

const Welcome = styled.div`
  text-align: left;
  margin: 2rem 5rem;
`
const Title = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
`
const Text = styled.p`
  color: ${({ theme }) => theme.colors.text };
`

const Cards = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 3rem 5rem;

  @media (min-width: 1400px) {
    justify-content: center;
  }
`

const Card = styled.div`
  margin: 1rem 3rem 4rem 3rem;
  flex: 0 0 25%;
  display: flex;
  min-width: 22rem;
  max-width: 22rem;
  min-height: 18rem;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  
  @media (min-width: 1400px) {
    width: 21rem;
  }
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  color: #424242;
  font-size: 1.6em;
  font-weight: 500;
`

const CardText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  font-weight: 500;
  margin-top: 1rem;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  margin: auto auto 0 auto;
  padding: .5rem 1.1rem;
  font-size: 1.1em;
  min-width: 7rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: .2rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Input = styled.input`
  height: 1.5rem;
  width: 10rem;
`

const ButtonIcon = styled.i`
  margin-left: .5rem;
`

const ErrorText = styled.div`
  text-align: center;
  font-size: .9em;
  color: ${({ theme }) => theme.colors.error };
  margin-top: .5rem;
  height: 1rem;
`

const SubTitle = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`

const Number = styled.div`
  font-size: 5em;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
  color: ${({ theme }) => theme.colors.primary };
`