import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ExportButton from '../common/ExportButton';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { dataSubscribers } from '../mockData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatDateMonths } from '../../services/utils';

const DataSubscribers = ({ view, handleView }) => {

  const { SearchBar } = Search;
  
  const dateFormatter = (cell, row) => {
    return (
      <span>{ formatDateMonths(cell) }</span>
    );
  };

  const columns = [{
    dataField: 'companyId',
    text: 'Company Id',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
      else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'startDate',
    text: <span>Subscription Start Date</span>,
    sort: true,
    formatter: dateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'type',
    text: 'Subscription Type',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }];

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true
  // };

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { size } entries
    </DataTableText>
  );

  const options = {
    custom: true,
    totalSize: dataSubscribers.length,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
    // showTotal: true,
  };

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Title>Data Partner Dashboard</Title>
        <Card>
          <TableContainer>
          <PaginationProvider
            pagination={ paginationFactory(options) }
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>

                  <ToolkitProvider
                    keyField="company"
                    data={ dataSubscribers }
                    columns={ columns }
                    exportCSV={ { exportAll: true } }
                    search
                  >
                    {
                      props => (
                        <DataTableContainer>
                          <Row style={{height: '5rem'}}>
                            <CardTitle><Icon><i className="fas fa-download"></i></Icon>Data Subscribers - Companies Using Your Data</CardTitle>
                            <StandAloneContainer>
                              <SearchBar 
                                style={{height: '1.6rem', marginRight: '1rem'}}
                                { ...props.searchProps } />
                              <SizePerPageDropdownStandalone
                                { ...paginationProps }
                              />
                              <DataTableText>
                                items/page
                              </DataTableText>
                            </StandAloneContainer>
                          </Row>
                          <BootstrapTable
                            { ...props.baseProps }
                            // selectRow={ selectRow }
                            { ...paginationTableProps }
                            striped
                            hover
                          />
                          <Row>
                            <PaginationTotalStandalone
                              { ...paginationProps }
                            />
                            <PaginationListStandalone
                              { ...paginationProps }
                            />
                          </Row>
                          <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } />
                        </DataTableContainer>
                      )
                    }
                  </ToolkitProvider>
                </div>
              )
            }
          </PaginationProvider>
          </TableContainer>
        </Card>
      </Content>
    </Container>
  );
}

export default DataSubscribers;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 600;
  align-items: center;
  padding: .5rem auto;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  margin: 0rem 4rem 1rem 4rem;
  padding: .2rem;
`

const TableContainer = styled.div`
  font-size: .9em;
  margin: 0;
  padding: 0;
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`