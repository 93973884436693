import React, { useState } from 'react';
import Loading from 'react-loading';
import styled from 'styled-components';

const isLoading = Comp => props => {
  const [loading, setLoading] = useState(false)
  const [loadingBlank, setLoadingBlank] = useState(false)

  return (
    <Relative>
      {loading && (
        <Container>
          <Loading type={'spin'} color={'#424242'} height={30} width={30}/>
        </Container>
      )}
      {loadingBlank && (
        <Container>
        </Container>
      )}
      <Comp {...props} setLoading={setLoading} setLoadingBlank={setLoadingBlank} />
    </Relative>
  )
}

export default isLoading

const Relative = styled.div`
  position: relative;
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`
