import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faSearch } from '@fortawesome/free-solid-svg-icons'
import SideNav from './SideNav';
import { getBaseURL, formatDateSlash } from '../../services/utils';
import Loading from 'react-loading';


const Pay = ({ getToken, view, handleView, environment, setAlert }) => {
	//const [personCount, setPersonCount] = useState(null);
	const [personid, setPersonid] = useState('')
	const [data, setData] = useState([])
	const [errorText, setErrorText] = useState('')
	const [searchMsg, setSearchMsg] = useState('Input Person ID to show results.')
	const [loading, setLoading] = useState(false)

	const fetchPay = useCallback(async() => {
		const { token } = await getToken();
		if (!token) return;
		setLoading(true);
		setData([]);
		try {
			const res = await fetch(`${getBaseURL(environment)}/persons/${personid}/pay`, {
				method: 'GET',
				headers: new Headers({
				  'Authorization': `Bearer ${token}`
				})
			})
			if (res.status === 200) {
				const { pays } = await res.json();
				setData(pays);
				return;
			}
			setSearchMsg('No results found')
		}
		catch(e) {
			console.log('Error fetching pay', e)
			setAlert({ type: 1 });
		}
		finally {
			setLoading(false);
		}
	}, [setAlert, environment, getToken, personid])

	const handleClick = async () => {
		if (personid === "") {
			return setErrorText("Input must not be blank")
		}
		if (!Number(personid)) {
			return setErrorText("Input must be a number")
		}
		setErrorText("")
		fetchPay()
	}

	const dataTable = () => {
		if(loading) {
			return(
			  <Row style={{padding: '2rem', justifyContent: 'center', maxWidth: '40%'}}>
				<Loading type={'spin'} color={'#424242'} height={30} width={30}/>
			  </Row>
			)
		}

		if (data.length) {
			return(
				<TableContainer>
					<table>
						<thead>
							<tr>
								<StyledTh style={{ textAlign: 'center' }}>Account Number</StyledTh>
								<StyledTh style={{ textAlign: 'center' }}>Created</StyledTh>
								<StyledTh style={{ textAlign: 'center' }}>Verified</StyledTh>
							</tr>
						</thead>
						<tbody>
							{data.map((da) => {
								return <StyledTr key={da.account_id}>
									<StyledTd>
										x{da.account_no}
									</StyledTd>
									<StyledTd>
										{formatDateSlash(da.created_at)}
									</StyledTd>
									<StyledTd>
										{da.verified
											? <Icon><i className="fas fa-check" style={{ margin: 'auto' }}></i></Icon>
											: <Icon><i className="fas fa-times" style={{ margin: 'auto' }}></i></Icon>
										}
									</StyledTd>
								</StyledTr>
							})}
						</tbody>
					</table>
					{data[0].verified
						? null
						: <p>
							To verify a Pay method for your customers, you must use our endpoint&nbsp;
							<a target="_blank" rel="noopener noreferrer" href='https://www.pentadatainc.com/docs/introduction/endpoints-reference/post-persons-personid-pay-verify/'>
								Pay-verify
							</a>
						</p>}
				</TableContainer>
			)
		} else {
			return(
				<NoDataTitle>{searchMsg}</NoDataTitle>
			)
		}
	}

	return (
		<Container>
			<SideNav view={view} handleView={handleView} />
			<Content>
				<Card>
					<CardTitle><Icon><FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon></Icon>Pay</CardTitle>
					<Row style={{ marginTop: '1rem' }}>
						<Input
							placeholder="Person ID"
							value={personid}
							onChange={(e) => setPersonid(e.target.value)}
							type="text"
							style={{ marginRight: '1rem' }}
						/>
						<SearchButton onClick={handleClick}>
							<FontAwesomeIcon icon={faSearch} />
						</SearchButton>
					</Row>
					<ErrorText>{errorText}</ErrorText>
					{dataTable()}
				</Card>
			</Content>
		</Container>
	);
}

export default Pay;

const Container = styled.div`
	display: flex;
	width: 100%;
`

const Card = styled.div`
	${({ theme }) => theme.animations.fadeIn};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: .25rem;
	background-color: #fff;
	padding: 2.5rem;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
	display: flex;
	align-items: center;
	color: #424242;
	font-size: 1.6em;
	font-weight: 700;
`

const Icon = styled.div`
	display: flex;
	align-items: center;
	padding: .2rem;
	color: ${({ theme }) => theme.colors.primary};
	margin-right: 1rem;
`

const Input = styled.input`
	height: 1rem;
	width: 10rem;
`

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

const StyledTd = styled.td`
	text-align: center;
	margin: auto;
`

const StyledTh = styled.th`
	color: ${({ theme }) => theme.colors.primary};
`

const StyledTr = styled.tr`
	td {
		color: ${({ theme }) => theme.colors.textSecondary}
	}
`

const TableContainer = styled.div`
	max-width: 50%;
`

const NoDataTitle = styled.div`
	display: flex;
	color: ${({ theme }) => theme.colors.text};
	font-size: 1.4em;
	font-weight: 400;
	align-items: center;
	padding: .5rem auto;
	justify-content: flex-start;
	width: 100%;
	height: 4rem;
	text-align: left;
`

const SearchButton = styled.button`
	background-color: ${({ theme }) => theme.colors.buttonPrimary};
	width: fit-content;
`

const ErrorText = styled.div`
	color: red;
	margin-top: .5rem;
	height: 1rem;
`

const Row = styled.div`
	display: flex;
`