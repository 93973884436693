import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setToken } from '../services/login';
import styled from 'styled-components';
import pentadataLogo from '../images/pentadata_logo_170.png'
import isLoading from '../components/common/isLoading';

const LoginConsumer = ({ 
  setMessage,
  getToken,
  setUserType,
  setAlert,
  login,
  setSlackMsg,
  setEnvironment,
  setView,
  webToken,
  userType
}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory();

  useEffect(() => {
    setEnvironment(1);
    setView('privacy');
  }, [setEnvironment, setView])

  const checkToken = async() => {
    if (webToken) {
      if (userType !== 'consumer') {
        history.push('/');
        return;
      }
      history.push('/consumers/privacy-control')
    }
  };

  useEffect(() => {
    checkToken();
  });

  const isValid = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setMessage('Email/password required')
      setAlert({ type: 0 });
      return;
    }
    handleSubmit()
  }

  const handleSubmit = useCallback(async() => {
    setMessage('');
    const creds = { email, password };
    try {
      const { token, refresh_token, message, userType, status } = await login(creds);
      switch(status) {
        case 200:
          if (userType === 'consumer') {
            setToken({ token, refresh_token });
            setUserType(userType);
            getToken();
            setMessage('');
            history.push('/consumers/privacy-control');
          } else {
            setAlert({ type: 0, login: true });
            setMessage('Invalid user account, try logging in at app.pentadatainc.com')
          }
          break;

        case 202:
          setMessage('Account MFA enabled, please contact customer service.')
          setAlert({ type: 0 });
          break;

        case 401:
          setMessage('Invalid credentials');
          setAlert({ type: 0 });
          break;

        case 429:
          setMessage(`
            This account was locked for security reasons. It will be unlocked automatically after a security check period. 
            Contact us immediately at support@pentadatainc.com to unlock it.
          `)
          setSlackMsg('I am having trouble logging into my account.');
          setAlert({ type: 0 , slack: true, email });
          break;

        default:
          if (message) {
            setMessage(message);
            setAlert({ type: 0 });
          }
      }
    } catch {
      setAlert({ type: 1 })
    }
  }, [email, password, setMessage, getToken, setUserType, setAlert, login, setSlackMsg, history])

  return (
    <Container>
      <LoginForm>
        <LogoTitle src={pentadataLogo} />
        <TitleText>DASHBOARD LOG IN</TitleText>
        <Form>
          <Input value={email} onChange={e => setEmail(e.target.value)} name='email' type='email' placeholder='Email' autoComplete='current-email' />
          <Input value={password} onChange={e => setPassword(e.target.value)} name='password' type='password' placeholder='Password' autoComplete='current-password' />
          <Button onClick={isValid}>Login</Button>
        </Form>
      </LoginForm>
    </Container>
  );
}

export default isLoading(LoginConsumer);


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem;
`

const TitleText = styled.div`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.pentaPrimary };
  margin-bottom: 2rem;
`

const Input = styled.input`
  padding: .4rem .75rem;
  margin-bottom: 1rem;
`


const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const LogoTitle = styled.img`
  max-width: 16rem;
  height: auto;
  width: auto;
  margin: auto;
`
