import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Confirmation = () => {
  return (
    <Container>
      <LoginForm style={{width: '23rem'}}>
        <Title>Confirmation</Title>
        <Text>Thanks for verifying your email address. You can now log in to the dashboard.</Text>
        <Link to="/"><Button style={{width: '7rem', fontSize: '1.3em', marginBottom: '1rem'}}>LOG IN</Button></Link>
      </LoginForm>
    </Container>
  );
}

export default Confirmation;


const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 17rem;
  border: 1px solid black;
  border-radius: 3px;
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
`

const Title = styled.div`
  width: 100%;
  font-size: 2.4em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };
`

const Text = styled.div`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text };
  margin: 1rem auto;
  width: 100%;
`
const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  color: #fff;
  font-size: 1.1em;
  height: 2.5rem;
  margin-top: 1rem;
  padding: 0.65rem 0.75rem;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`