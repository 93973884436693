import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ContributorCircleBar = ({ value, total, text, text2 }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <CircularProgressbarWithChildren
        value={value} maxValue={total} text={`${value}`} strokeWidth={`9`}
        styles={{
          root: {},
          path: {
            stroke: themeContext.colors.primary,
            strokeLinecap: 'butt',
            transition: 'stroke-dashoffset 0.5s ease 0s',
            // transform: 'rotate(0.25turn)',
            // transformOrigin: 'center center',
          },
          trail: {
            stroke: '#DDDDDD',
            strokeLinecap: 'butt',
            // transform: 'rotate(0.25turn)',
            // transformOrigin: 'center center',
          },
          text: {
            fill: themeContext.colors.text,
            fontSize: '3em',
          },
          background: {
            fill: '#3e98c7',
          },
        }}
      >
        <div style={{ lineHeight: '1', textAlign: 'center', fontSize: 10, marginTop: 270, fontWeight: 'bold', fontFamily: themeContext.text, color: themeContext.colors.text }}>
          {text} <br />
          {text2}
        </div>
      </CircularProgressbarWithChildren>
    </>
  );
}

export default ContributorCircleBar;
