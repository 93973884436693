import React, { useState } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import ExportButton from '../common/ExportButton';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { consumerConsents } from '../mockData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatDateMonths } from '../../services/utils';
import Modal from 'react-modal';
import { modalStyles } from '../../Theme';

Modal.setAppElement('#root')

const ConsumerConsents = ({ view, handleView }) => {
  const [openModal, setOpenModal] = useState(false);

  const { SearchBar } = Search;
  
  const dateFormatter = (cell, row) => {
    if (cell === null) return '';
    return (
      <span>{ formatDateMonths(cell) }</span>
    );
  };

  const optInFormFormatter = (cell, row) => {
    if (cell == null) return '';
    return (
      <Link onClick={() => setOpenModal(true)}>{cell}</Link>
    )
  }

  const columns = [{
    dataField: 'consumerId',
    text: 'Consumer Id',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
      else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'optInDate',
    text: 'Opt-In-Date',
    sort: true,
    formatter: dateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'optOutDate',
    text: 'Opt-Out-Date',
    sort: true,
    formatter: dateFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'optInForm',
    text: 'Opt-In-Form',
    sort: true,
    formatter: optInFormFormatter,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }, {
    dataField: 'optInSource',
    text: 'Opt-In-Source',
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (
          <i className="fas fa-sort custom-sort-icon"></i>
        );
      else if (order === 'asc') return (
          <i className="fas fa-sort-up custom-sort-icon-active"></i>
        );
        else if (order === 'desc') return (
          <i className="fas fa-sort-down custom-sort-icon-active"></i>
        );
      return null;
    }
  }];

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true
  // };

  const customTotal = (from, to, size) => (
    <DataTableText style={{margin: '0'}}>
      Showing { from } to { to } of { size } entries
    </DataTableText>
  );

  const options = {
    custom: true,
    totalSize: consumerConsents.length,
    firstPageText: '<<',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: '>>',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
    // showTotal: true,
  };

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Title>Data Partner Dashboard</Title>
        <Card>
          <TableContainer>
          <PaginationProvider
            pagination={ paginationFactory(options) }
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>

                  <ToolkitProvider
                    keyField="company"
                    data={ consumerConsents }
                    columns={ columns }
                    exportCSV={ { exportAll: true } }
                    search
                  >
                    {
                      props => (
                        <DataTableContainer>
                          <Row style={{height: '5rem'}}>
                            <CardTitle><Icon><i className="fas fa-user-check"></i></Icon>Consumer Consents</CardTitle>
                            <StandAloneContainer>
                              <SearchBar 
                                style={{height: '1.6rem', marginRight: '1rem'}}
                                { ...props.searchProps } />
                              <SizePerPageDropdownStandalone
                                { ...paginationProps }
                              />
                              <DataTableText>
                                items/page
                              </DataTableText>
                            </StandAloneContainer>
                          </Row>
                          <BootstrapTable
                            { ...props.baseProps }
                            // selectRow={ selectRow }
                            { ...paginationTableProps }
                            striped
                            hover
                          />
                          <Row>
                            <PaginationTotalStandalone
                              { ...paginationProps }
                            />
                            <PaginationListStandalone
                              { ...paginationProps }
                            />
                          </Row>
                          <ExportButton style={{fontSize: '1.2em'}} text={'Download Data'} { ...props.csvProps } />
                        </DataTableContainer>
                      )
                    }
                  </ToolkitProvider>
                </div>
              )
            }
          </PaginationProvider>
          </TableContainer>
        </Card>
        <Modal
          isOpen={openModal}
          style={modalStyles}
          contentLabel="Opt In Form"
          overlayClassName="modal-overlay"
        >
          <ModalContent>
            <ModalRow>
              <ModalTitle>Opt In Form</ModalTitle>
              <CloseButton onClick={() => setOpenModal(false)}>
                <i className="fas fa-times"></i>
              </CloseButton>
            </ModalRow>
            <OptInFormText>
              <h4 class="lh-3 mg-b-20">Standard Terms for Consent:</h4>
              <p>By agreeing to participate in the [insert NAME of Company card-linked program] (“Program”), you authorize [Company] to collect and use your [enrolled and activated card(s) in Program (each an “Enrolled Card”) information (“Enrolled Card Information”), and your other Program-related information, including without limitation information about your [Offers], interaction and redemption of Offers, and account activity (collectively, “Program Information”) and Transaction Information provided by Payment Processors as follows.</p>
              <span class="tx-bold">Share Data</span><br/>
              <span class="tx-bold">a.</span> Share your Enrolled Card information and Program Information with Payment Processors to:
              <ul>
                <li>i. enable Payment Processors to monitor your transactions, collect and provide [Company] with details about transactions related to [Offers] (including date, time, Merchant name and ID, last four digits of the credit or debit card, and the amount of the transaction) (“Transaction Information”); and</li>
                <li>ii. confirm a purchase or return to match transactions to confirm whether you qualify for an [Offer] or otherwise determine account status.</li>
              </ul>
              <span class="tx-bold">b.</span> Share your Program Information and Transaction Information with Merchants to:
                <ul>
                  <li>i. confirm a purchase or return to match transactions to confirm whether you qualify for an [Offer] or otherwise determine account status; and</li>
                  <li>ii. allow Merchants to assess the results of Merchant campaigns.</li>
                </ul>
              <span class="tx-bold">c.</span> Share your Program Information with other companies or individuals to perform functions on our behalf (“Third Party Servicers”). These functions may include,
                among other things, analyzing data; providing marketing assistance; or other business functions.
            </OptInFormText>
            <ModalRow style={{justifyContent: 'flex-end'}}>
              <ModalButton onClick={() => setOpenModal(false)}>Close</ModalButton>
            </ModalRow>
          </ModalContent>
        </Modal>
      </Content>
    </Container>
  );
}

export default ConsumerConsents;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  margin: 1rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 600;
  align-items: center;
  padding: .5rem auto;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  margin: 0rem 4rem 1rem 4rem;
  padding: .2rem;
`

const TableContainer = styled.div`
  font-size: .9em;
  margin: 0;
  padding: 0;
`

const DataTableContainer = styled.div`
  margin-top: 1rem;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const DataTableText = styled.div`
  margin-left: 1rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textSecondary };
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
`

const ModalTitle = styled.div`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  margin-bottom: .5rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1.1em;
  width: 5rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const ModalRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const Link = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary };
`

const OptInFormText = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary };
  max-height: 30rem;
  max-width: 30rem;
  overflow: scroll;
`

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.toggleOff };
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary };
  }
`