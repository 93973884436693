import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHardHat } from '@fortawesome/free-solid-svg-icons'

export default function ErrorPage() {
  return (
    <Container>
      <Content>
        <Icon><FontAwesomeIcon icon={faHardHat} size='5x' color=''/></Icon>
        <Text>
          We are making updates to this page that will be released soon.
        </Text>
        <Text>
          Please try refreshing, we apologize for the inconvenience.
        </Text>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const Content = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.text };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  min-height: 15rem;
  padding: 2.5rem;
`

const Text = styled.div`
  font-size: 1.8em;
  font-weight: 500;
  margin: .5rem;
`

const Icon = styled.div`
  color: ${({ theme }) => theme.colors.primary };
  padding: 1rem;
  margin: 1rem;
`
