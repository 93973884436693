import React, { useState, useEffect, useCallback } from 'react';
import { 
  useParams 
} from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import SourceConsent from '../components/common/SourceConsent';
import { YODLEE_FASTLINK_URL } from '../services/utils';

const Account = () => {
  let { qstr } = useParams();
  let { c, s, redirect, consent, t, f_redirect, oauth } = queryString.parse(qstr);
  const { redirectUri } = queryString.parse(redirect);
  const [showConsent, setShowConsent] = useState(false);

  const openIframe = useCallback((url) => {
    window.finicityConnect.launch(url, {
      selector: '#connect-container',
      overlay: 'rgba(255,255,255, 0)',
      success: ({ reason }) => {
        console.log('', reason);
        if (reason === 'complete') {
          if (redirectUri) {
              window.location.replace(redirectUri);
          }
        }
      },
      cancel: ({ reason }) => {
        if (reason === 'exit') {
          if (redirectUri) {
              window.location.replace(redirectUri);
          }
        }
      },
      error: (error) => {
        console.error('', error);
      },
      loaded: () => {
        console.log('');
      },
      route: (event) => {
        console.log('', event);
      },
      user: ({ data }) => {
        console.log('', data);
        // const { action } = data;
        // if (action === "SubmitAccounts") {
        //   if (redirectUri) {
        //     window.location.replace(redirectUri);
        //   }
        // }
      }
    });
  }, [redirectUri])

  const openYodlee = useCallback(() => {
    if (!t) return
    const configName = process.env.REACT_APP_YODLEE_CONFIG_NAME
    const config = {
      fastLinkURL: YODLEE_FASTLINK_URL,
      params: {
        userExperienceFlow: 'Verification',
        callbackLocation: 'top',
        configName,
      },
      iframeScrolling: 'yes',
      onSuccess: function(data) {
        console.log(data);
      },
      onError: function(data) {
        console.log(data)
      },
      onExit: function(data) {
        console.log(data)
        if (f_redirect) {
          window.location.replace(f_redirect)
        }
      },
      onEvent: function(data) {
        console.log(data)
      }
    }
    if (f_redirect) {
      config.params.callback = f_redirect
    }
    config.accessToken = 'Bearer ' + t
    window.fastlink.open(config, 'fastlink-container')
  }, [t, f_redirect])

  const buildUrl = (url) => {
    if (!url) return
    console.log("URL: ", url)
    const split = url.split('?')
    const params = queryString.parse(split[1])
    console.log("Params: ", params)
    split[1] = queryString.stringify(params)
    const result = split.join('?')
    console.log("Result: ", result)
    return split.join('?')
  }

  const mxConnect = new window.MXConnect({
    id: "connect-widget",
    iframeTitle: "Connect",
    onEvent: function (type, payload) {
      if (type === "mx/connect/memberConnected") {
        // console.log("onEvent", type, payload)
        if (f_redirect) {
          window.location.replace(f_redirect)
        }
      }
    },
    config: {
      ui_message_version: 4
    },
    targetOrigin: "*",
  })

  const handleRender = useCallback(() => {
    const url = buildUrl(redirect)
    if (c === 'finicity') {
      setShowConsent(false)
      if (oauth === 'true') {
        window.location.assign(url)
      } else {
        openIframe(url)
      }
      return
    }
    if (c === 'yodlee') {
      setShowConsent(false)
      openYodlee()
      return
    }
    if (c === 'mx') {
      setShowConsent(false)
      mxConnect.load(redirect)
      return
    }
    window.location.assign(url)
  }, [c, oauth, openIframe, redirect, openYodlee, mxConnect])

  useEffect(() => {
    if (consent && consent === 'n') {
      handleRender()
    } else {
      setShowConsent(true)
    }
    // eslint-disable-next-line
  }, [])

  if (!c || !s) return <Container />

  const render = () => {
    if (showConsent) {
      return(
        <Content>
          <SourceConsent contributor={c} subscriber={s} />
          <Row style={{justifyContent: 'flex-end', padding: '0 2rem 1rem 1rem', width: '100%'}}>
            <Button onClick={handleRender}>
              I accept - Continue
            </Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Container>
      <div>
        <div id='connect-container' />
        <div id='fastlink-container' />
        <div id='connect-widget' />
      </div>
      {render()}
    </Container>
  );
}

export default Account;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #3a3a3a;
  text-align: center;
`

const Content = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  margin-left: 1rem;
  padding: .5rem .75rem;
  font-size: 1.1em;
`
