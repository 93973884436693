import React, { useState, useEffect, useCallback, createContext } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { removeToken, setToken } from './services/login';
import {
  BASE_URL, getBaseURL,
  isTokenValid,
  SANDBOX_URL,
  buildTrackingData,
} from './services/utils';
import { decipherToken } from './services/login';
import styled from 'styled-components';
import Theme from './Theme';
import Loading from 'react-loading';
import Login from './components/Login';
import LoginConsumer from './components/LoginConsumer';
import Signup from './components/Signup';
import ResetPassword from './components/ResetPassword';
import Confirmation from './components/Confirmation';
import NoPage from './components/NoPage';
import SubscriberDashboard from './components/subscriber/SubscriberDashboard';
import ContributorDashboard from './components/partners/PartnersDashboard';
import Nav from './components/Nav';
import Alerts from './Alerts';
import Account from './components/Account';
import AccountLite from './components/AccountLite';
import AuthProduct from './components/AuthProduct';
import Privacy from './components/consumer/Privacy';
import ErrorBoundary from './components/common/ErrorBoundary';
import './App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import ResetConfirmation from './components/ResetConfirmation';
import ResetExpired from './components/ResetExpired';
// import 'bootstrap/dist/css/bootstrap.min.css';

export const AppContext = createContext();

function App() {
  const [view, setView] = useState('overview');
  const [tokenState, setTokenState] = useState('expired');
  const [webToken, setWebToken] = useState(null);
  const [userType, setUserType] = useState(null);
  const [message, setMessage] = useState('');
  const [slackMsg, setSlackMsg] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [trackingData, setTrackingData] = useState([]);
  // default environment toggle: 0 = sandbox, 1 = production
  const [environment, setEnvironment] = useState(1);
  const [alert, setAlert] = useState({type: null});
  const [personData, setPersonData] = useState(null);
  const [personAccounts, setPersonAccounts] = useState(null);
  const [personCards, setPersonCards] = useState(null);
  const [accountSearch, setAccountSearch] = useState('');
  const [merchantSearch, setMerchantSearch] = useState('');
  const [switchingEnvironment, setSwitchingEnvironment] = useState(false);
  const [currentPerson, setCurrentPerson] = useState(null);

  const [accountsData, setAccountsData] = useState(null);

  const [personsOffset, setPersonsOffset] = useState(1);
  const [personsLimit, setPersonsLimit] = useState(25);

  const [sightSearch, setSightSearch] = useState({
    text: '',
    city: '',
    postal: ''
  });

  // Check if token is expired and returns token to be used for other functions.

  const getToken = useCallback(async () => {
    let token = localStorage.getItem('token');
    let nowInSeconds = Date.now() / 1000;
    let expiredToken = () => { setTokenState('expired'); return ({ tokenStatus: 'expired' }); }

    if (!token) {
      setTokenState('login')
      return { token: null };
    }

    const payload = JSON.parse(atob(token.split('.')[1]));

    let userType = payload.type;
    let environment = payload.environment;

    if (payload.user_claims) {
      userType = payload.user_claims.type;
      environment = payload.user_claims.environment;
    }
    
    setUserType(userType);
    if (environment === 'test') {
      setEnvironment(0);
    } else {
      setEnvironment(1);
    }

    if (payload.exp >= nowInSeconds) {
      setWebToken(token);
      setTokenState('valid');
      return ({ token, tokenStatus: 'valid' });
    }

    localStorage.removeItem('token');
    let refresh_token = localStorage.getItem('refresh token');

    if (!refresh_token) {
      return expiredToken;
    }

    const refresh_payload = JSON.parse(atob(refresh_token.split('.')[1]));
    if (refresh_payload.exp < nowInSeconds) {
      return expiredToken;
    } 
      
    let response = await fetch(`${getBaseURL(environment)}/subscribers/refresh`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${refresh_token}`
      })
    })
      .catch(error => {
        console.log("Error refreshing token", error);
        setAlert({ type: 1 })
      })
    let data = await response.json();
    if (data.token) {
      setWebToken(data.token);
      setToken({ token: data.token });
      setTokenState('valid');
      return ({ token: data.token, tokenStatus: 'valid' });
    }
    
  }, [])

  const login = useCallback(async(creds) => {
    try {
      const res = await fetch(`${getBaseURL(environment)}/login`, {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json'}),
        body: JSON.stringify(creds)
      })
      const { token, refresh_token, message, signature } = await res.json();
      if (token) {
        const tokenInfo = decipherToken(token);
        let userType = tokenInfo.type;
        if (tokenInfo.user_claims) {
          userType = tokenInfo.user_claims.type
        }
        return { token, refresh_token, message, signature, userType, status: res.status };
      }
      return { message, signature, status: res.status };
    }
    catch(e) {
      console.log('Error logging in', e);
      setAlert({ type: 1 });
      return null;
    }
  }, [environment])
  
  const mfaLogin = useCallback(async(creds) => {
    try {
      const res = await fetch(`${getBaseURL(environment)}/mfa-login`, {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json'}),
        body: JSON.stringify(creds)
      })
      const { token, refresh_token, message } = await res.json()
      if (token) {
        const payload = decipherToken(token);
        const userType = payload.type;
        return ({ userType, token, refresh_token, message, status: res.status });
      } else {
        return ({ message, status: res.status });
      }
    }
    catch(e) {
      console.log('Error logging in with MFA', e);
      setAlert({ type: 1 });
      return null;
    }
  }, [environment])

  const deleteAccount = useCallback(async () => {
    const { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(getBaseURL(environment) + '/subscribers/delete', {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          })
        })
        if (res.status === 200) {
          setMessage('Account deleted, you will now be logged out.')
          return { status: res.status };
        }
      }
      catch (e) {
        setAlert({ type: 1 });
      }
    }
  }, [getToken, environment])

  const fetchDashboardData = useCallback(({ token }) => {
    fetch(getBaseURL(environment) + '/consumers', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
    .then(res => res.json())
    .then(data => {
      setDashboardData(data);
    })
    .catch(err => console.log("error fetching data", err))
  },[environment])

  const fetchTrackingData = useCallback(({ token }) => {
    fetch(BASE_URL + '/consumers/subscribers', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
    })
    .then(data => {
      setTrackingData(buildTrackingData(data))
    })
    .catch(err => {
      console.log("error fetching data", err)
      setAlert({ type: 1 });
    })
  }, [])

  const fetchAccountsData = useCallback(({ token }) => {
    fetch(BASE_URL + '/accounts', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        setAccountsData(data.accounts);
      })
      .catch(err => {
        setAlert({ type: 1 });
        console.log("Error fetching account data", err);
      })
  }, [setAccountsData])

  const fetchSubscriberData = useCallback(({ token, environment }) => {
    fetch(getBaseURL(environment) + '/subscribers', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
    .then(res => res.json())
    .then(data => {
      setDashboardData(data);
    })
    .catch(err => console.log("Error fetching subscriber data", err))
  }, [])

  const fetchPerson = useCallback(async({ id, token }) => {
    try {
      const res = await fetch(`${getBaseURL(environment)}/persons/${id}` , {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      if (res.status !== 200) return
      const data = await res.json()
      setPersonData({
        persons: [data],
        total: 1
      })
      return true
    }
    catch(e) {
      console.log('Error fetching person', e)
    }
  }, [environment])

  const fetchPersons = useCallback(({
    token,
    environment,
    offset=personsOffset,
    limit=personsLimit
  }) => {
    const totalOffset = (offset - 1) * limit
    fetch(`${getBaseURL(environment)}/persons?offset=${totalOffset}&limit=${limit}` , {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
    .then(res => res.json())
    .then(data => {
      setPersonData(data);
    })
    .catch(err => console.log("Error fetching subscriber data", err))
  }, [personsLimit, personsOffset])

  const fetchPersonAccounts = useCallback( async({ personId, endpoint, token }) => {
    try {
      const res = await fetch(getBaseURL(environment) + `/persons/${personId}/${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      if (res.status === 200) {
        const { accounts, cards } = await res.json();
        if (accounts) {
          setPersonAccounts(accounts);
        }
        if (cards) {
          setPersonCards(cards);
        }
        return true;
      } else {
        return null;
      }
    }
    catch(err) {
      console.log("Error fetching subscriber data", err)
    }
  }, [environment])

  const requestProduction = useCallback(async() => {
    const { token } = await getToken();
    if (!token) return null;
    try {
      const res = await fetch(SANDBOX_URL + '/get-production', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'applicatoin/json',
          'Authorization': `Bearer ${token}`
        }),
      })
      if (res.status === 200) {
        setMessage('Thank you for your submission, a team member will be in touch with you soon.')
        setAlert({ type: 0 });
      } else {
        setAlert({ type: 0, contact: true });
        setMessage('There was a problem with your request. Please try again.')
      }
    }
    catch(e) {
      console.log('Error requestion production', e);
      setAlert({ type: 1 });
    }
  }, [getToken])

  const sendSlack = useCallback(async (message) => {
    let msg = `Environment: ${window.location.hostname}\n${message}`
    try {
      const res = await fetch(process.env.REACT_APP_SLACK_URL, {
        method: 'POST',
        body: JSON.stringify({
            text: msg
        })
      })
      if (res.status !== 200) {
        setAlert({ type: 0 });
        setMessage('There was a problem sending message.')
        return;
      }
      setAlert({ type: 0 });
      setMessage('Message received. A team member will be in touch with you soon.');
      return;
    } 
    catch(e) {
      console.log('Error sending slack', e)
      setAlert({ type: 1 });
    }
  }, [setAlert])

  const handleView = useCallback((view) => {
    setView(view);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const handleLogout = useCallback(async() => {
    removeToken();
    setTokenState(null);
    setWebToken(null);
    setDashboardData(null);
    setUserType(null);
    setView('overview');
  }, [])

  useEffect(() => {
    getToken();
    if (webToken && dashboardData === null && !switchingEnvironment) {
      if (userType === 'consumer') {
        fetchDashboardData({ token: webToken });
        fetchAccountsData({ token: webToken });
        fetchTrackingData({ token: webToken });
      }
      if (userType === 'subscriber') {
        fetchSubscriberData({ token: webToken, environment });
        fetchPersons({ token: webToken, environment });
      }
      if (userType === 'contributor') {
        setDashboardData('testing');
      }
    }
  }, [getToken, tokenState, webToken, dashboardData, userType, switchingEnvironment, personsLimit, personsOffset,
    fetchDashboardData, fetchSubscriberData, fetchPersons, environment, fetchAccountsData, fetchTrackingData])

  const dashboardSwitch = (userType) => {
    switch(userType) {

      case 'subscriber':
        return(
          <SubscriberDashboard
            view={view} 
            dashboardData={dashboardData} 
            fetchDashboardData={fetchDashboardData}
            handleView={handleView}
            fetchSubscriberData={fetchSubscriberData}
            webToken={webToken}
            environment={environment}
            personData={personData}
            setMessage={setMessage}
            message={message}
            getToken={getToken}
            handleLogout={handleLogout}
            deleteAccount={deleteAccount}
            personAccounts={personAccounts}
            personCards={personCards}
            fetchPersonAccounts={fetchPersonAccounts}
            setPersonAccounts={setPersonAccounts}
            setPersonCards={setPersonCards}
            accountSearch={accountSearch}
            setAccountSearch={setAccountSearch}
            merchantSearch={merchantSearch}
            setMerchantSearch={setMerchantSearch}
            setAlert={setAlert}
            requestProduction={requestProduction}
            currentPerson={currentPerson}
            setCurrentPerson={setCurrentPerson}
            userType={userType}
          />
        )

      case 'contributor':
        return(
          <ContributorDashboard
            view={view} 
            dashboardData={dashboardData} 
            handleView={handleView}
          />
        )

      case 'consumer':
        return(
          <Redirect to='/consumers/privacy-control' />
        )

      default: return null;
    }
  }

  const loggedIn = () => {
    if (!webToken) {
      return(
        <Login 
          tokenState={tokenState} 
          handleLogout={handleLogout} 
          message={message}
          setMessage={setMessage}
          getToken={getToken}
          setUserType={setUserType}
          environment={environment}
          setEnvironment={setEnvironment}
          setView={setView}
          setAlert={setAlert}
          login={login}
          mfaLogin={mfaLogin}
          setSlackMsg={setSlackMsg}
        />  
      )
    }

    if (!dashboardData) {
      return(
        <LoadingContainer>
          <Loading type={'spin'} color={'#424242'} height={30} width={30}/>
        </LoadingContainer>
      )
    }

    return(
      <>
        <Nav 
          handleLogout={handleLogout} 
          view={view} 
          setView={setView} 
          userType={userType}
          environment={environment}
          setEnvironment={setEnvironment}
          getToken={getToken}
          setUserType={setUserType}
          setAlert={setAlert}
          setMessage={setMessage}
          message={message}
          fetchSubscriberData={fetchSubscriberData}
          fetchPersons={fetchPersons}
          setSwitchingEnvironment={setSwitchingEnvironment}
        />
        <ErrorBoundary setView={setView}>
          {dashboardSwitch(userType)}
        </ErrorBoundary>
      </>
    )
  }

  const consumerHome = () => {
    return(
      <>
        <Nav 
          handleLogout={handleLogout} 
          view={view} 
          setView={setView} 
          userType={userType}
          environment={environment}
          setEnvironment={setEnvironment}
          getToken={getToken}
          setUserType={setUserType}
          setAlert={setAlert}
          setMessage={setMessage}
          message={message}
          fetchSubscriberData={fetchSubscriberData}
          fetchPersons={fetchPersons}
          setSwitchingEnvironment={setSwitchingEnvironment}
        />
        <Privacy
          view={view}
          handleView={handleView} 
          dashboardData={dashboardData}
          getToken={getToken}
          setMessage={setMessage}
          setAlert={setAlert}
          fetchDashboardData={fetchDashboardData}
          fetchAccountsData={fetchAccountsData}
          fetchTrackingData={fetchTrackingData}
          accountsData={accountsData}
          trackingData={trackingData}
          sendSlack={sendSlack}
          webToken={webToken}
          userType={userType}
          setEnvironment={setEnvironment}
        />
      </>
    )
  }

  const showAlert = (
    <Alerts 
      alert={alert}
      setAlert={setAlert}
      setMessage={setMessage}
      message={message}
      dashboardData={dashboardData}
      setView={setView}
      sendSlack={sendSlack}
      slackMsg={slackMsg}
      setSlackMsg={setSlackMsg}
    />
  )

  // Start converting components to use context
  const value = {
    view,
    handleView,
    getToken,
    environment,
    dashboardData,
    personData,
    setPersonData,
    fetchPerson,
    fetchPersons,
    fetchPersonAccounts,
    setCurrentPerson,
    personsLimit,
    setPersonsLimit,
    personsOffset,
    setPersonsOffset,
    sightSearch,
    setSightSearch,
    accountSearch,
    setAccountSearch,
    merchantSearch,
    setMerchantSearch
  }

  return (
    <AppContext.Provider value={value}>
      <Theme>
        <Router>
          <Switch>
            <Route exact path="/">
              <Container>
                {loggedIn()}
                {showAlert}
              </Container>
            </Route>
            <Route exact path="/consumers/login">
              <Container>
                <LoginConsumer
                  handleLogout={handleLogout}
                  message={message}
                  setMessage={setMessage}
                  getToken={getToken}
                  setUserType={setUserType}
                  setView={setView}
                  setAlert={setAlert}
                  login={login}
                  mfaLogin={mfaLogin}
                  setSlackMsg={setSlackMsg}
                  environment={environment}
                  setEnvironment={setEnvironment}
                  webToken={webToken}
                  userType={userType}
                />
                {showAlert}
              </Container>
            </Route>
            <Route exact path="/consumers/privacy-control">
              <Container>
                {consumerHome()}
                {showAlert}
              </Container>
            </Route>
            <Route exact path="/signup">
              <Container>
                <Signup />
                {showAlert}
              </Container>
            </Route>
            <Route exact path="/set-pw">
              <Container>
                <ResetPassword 
                  setView={setView}
                  setAlert={setAlert}
                  setMessage={setMessage}
                  environment={environment}
                  setEnvironment={setEnvironment}
                />
                {showAlert}
              </Container>
            </Route>
            <Route exact path="/confirmation">
              <Container>
                <Confirmation />
              </Container>
            </Route>
            <Route exact path='/reset-expired'>
              <Container>
                <ResetExpired />
              </Container>
            </Route>
            <Route exact path='/reset-confirmation'>
              <Container>
                <ResetConfirmation />
              </Container>
            </Route>
            <Route path='/account/:qstr'>
              <Container>
                <Account />
              </Container>
            </Route>
            <Route path='/fin-accounts/:qstr'>
              <Container>
                <AccountLite
                  setAlert={setAlert}
                  setMessage={setMessage}
                />
                {showAlert}
              </Container>
            </Route>
            <Route path='/auth/:token/:qstr'>
              <Container>
                <AuthProduct />
              </Container>
            </Route>
            <Route path='/*'>
              <Container>
                <NoPage />
              </Container>
            </Route>
          </Switch>
        </Router>
      </Theme>
    </AppContext.Provider>
  );
}

export default App;

const Container = styled.div`
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
  height: auto;
  width: 100%;
  margin: auto;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  width: 100%;
`
