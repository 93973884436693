import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import logo from '../images/pentadata_logo_170.png'
import Toggle from './common/ToggleSwitch';
import SwitchEnvironment from './SwitchEnvironment';
import { modalStyles } from '../Theme';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const Nav = ({ handleLogout, view, setView, userType, environment, setMessage, setSwitchingEnvironment,
  message, getToken, setUserType, setAlert, fetchSubscriberData, fetchPersons, setEnvironment
}) => {
  // const [showMenu, setShowMenu] = useState(false)
  const [openLogin, setOpenLogin] = useState(false);
  const themeContext = useContext(ThemeContext);

  // const dropDown = showMenu
  //   ? <Dropdown onMouseLeave={() => setShowMenu(false)}>
  //       Test
  //     </Dropdown>
  //   : ''

  const showLogin = openLogin
    ? <Modal
        isOpen={openLogin}
        style={modalStyles}
        contentLabel="Form"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <SwitchEnvironment 
            setMessage={setMessage}
            message={message}
            getToken={getToken}
            setUserType={setUserType}
            environment={environment}
            setEnvironment={setEnvironment}
            setOpenLogin={setOpenLogin}
            setAlert={setAlert}
            fetchSubscriberData={fetchSubscriberData}
            fetchPersons={fetchPersons}
            handleLogout={handleLogout}
            setSwitchingEnvironment={setSwitchingEnvironment}
          />
        </ModalContent>
      </Modal>
    : <></>

  const navSwitch = (userType) => {
    switch(userType) {

      case 'consumer':
        return(
          <>
            {/* <NavLink 
              onClick={() => setView('support')}
              style={{color: `${view === 'support' ? themeContext.colors.primary : ``}`, fontWeight: `${view === 'support' ? `700` : ``}`}}
              >
              Support
            </NavLink>
            <NavLink
              onClick={() => setView('faqs')}
              style={{color: `${view === 'faqs' ? themeContext.colors.primary : ``}`, fontWeight: `${view === 'faqs' ? `700` : ``}`}}
              >
              FAQs
            </NavLink> */}
            <NavLink onClick={() => handleLogout()}>
              Logout
            </NavLink>
          </>
        )

      case 'subscriber':
        return(
          <>
            <NavLink 
              style={{
                flexDirection: 'column', 
                padding: '0', 
                justifyContent: 'flex-end',
                width: 'auto',
                cursor: 'auto'
              }}
            >
              <Toggle 
                id={`toggle-environment-nav`}
                isOn={environment} 
                handleToggle={() => setOpenLogin(true)}
                small={true}
              />
              <ToggleText>
                {environment === 0 ? 'Sandbox' : 'Production'}
              </ToggleText>
            </NavLink>
            {environment
              ? <NavLink 
                  onClick={() => setView('billing')}
                  style={{
                    color: `${view === 'billing' ? themeContext.colors.primary : ``}`, 
                    fontWeight: `${view === 'billing' ? `500` : ``}`
                  }}
                  >
                  Billing
                </NavLink>
              : <NavLink 
                  onClick={() => setView('request prod')}
                  style={{
                    color: `${view === 'request prod' ? themeContext.colors.primary : ``}`, 
                    fontWeight: `${view === 'request prod' ? `500` : ``}`,
                    width: '12rem'
                  }}
                  >
                  Request Production Access
                </NavLink>
            }
            <NavLink 
              onClick={() => setView('support')}
              style={{
                color: `${view === 'support' ? themeContext.colors.primary : ``}`, 
                fontWeight: `${view === 'support' ? `500` : ``}`
              }}
              >
              Support
            </NavLink>
            <NavLink
              onClick={() => setView('subscriber profile')}
              style={{
                color: `${view === 'subscriber profile' ? themeContext.colors.primary : ``}`, 
                fontWeight: `${view === 'subscriber profile' ? `500` : ``}`
              }}
              >
              Profile
            </NavLink>
            <NavLink onClick={() => handleLogout()}>
              Logout
            </NavLink>
          </>
        )

      case 'contributor':
        return(
          <>
            <NavLink 
              onClick={() => setView('support')}
              style={{color: `${view === 'support' ? themeContext.colors.primary : ``}`, fontWeight: `${view === 'support' ? `700` : ``}`}}
              >
              Support
            </NavLink>
            <NavLink
              onClick={() => setView('faqs')}
              style={{color: `${view === 'faqs' ? themeContext.colors.primary : ``}`, fontWeight: `${view === 'faqs' ? `700` : ``}`}}
              >
              FAQs
            </NavLink>
            <NavLink onClick={() => handleLogout()}>
              Logout
            </NavLink>
          </>
        )

      default: return null;
    }
  }


  return (
    <Container>
      <Logo onClick={() => setView('overview')}>
        <img className="logo" alt="logo" src={logo}/>
      </Logo>
      <LinksContainer>
        {navSwitch(userType)}
        {/* <NavDropdown onMouseEnter={() => setShowMenu(true)}>
          Logout
        </NavDropdown>
        {dropDown} */}
      </LinksContainer>
      {showLogin}
    </Container>
  );
}

export default Nav;

const Container = styled.div`
  width: 100%;
  background-color: #FFF;
  color: ${({ theme }) => theme.colors.navText };
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

// const DropdownLink = styled.div`  
//   display: none;
//   z-index: 1;
//   min-width: 8rem;
//   background-color: #fff;
//   position: relative;
// `

const NavLink = styled.div`
  display: flex;
  align-items: center;
  width: 3rem;
  margin: .5rem;
  padding: 1rem .4rem;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  justify-content: center;
`

const Logo = styled.div`
  display: flex;
  align-self: flex-start;
  margin: auto auto auto 3rem;
  padding: .2rem auto;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const ToggleText = styled.div`
  font-size: .8em;
  margin-top: 5px;
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  min-width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// const NavDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   margin: .5rem;
//   padding: 1rem .4rem;
//   cursor: pointer;
//   font-weight: bold;
//   position: relative;
// `

// const Dropdown = styled.div`
//   display: block;
//   top: 4.5rem ;
//   position: absolute;
//   background-color: #fff;
//   min-width: 160px;
//   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
//   z-index: 1;

//   ${NavDropdown}:hover & {
//     display: block;
//   }
// `

// const DropdownContent = styled.div`
//   float: none;
//   color: black;
//   padding: 12px 16px;
//   text-decoration: none;
//   display: block;
//   text-align: left;
// `
  