import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Toggle from '../common/ToggleSwitch';
import Modal from 'react-modal';
import { modalStyles } from '../../Theme';
import { getBaseURL } from '../../services/utils';
import Loading from 'react-loading';

const YourApis = ({ view, handleView, webToken, dashboardData, fetchSubscriberData, environment }) => {
  const [transactions, setTransactions] = useState(false);
  const [auth, setAuth] = useState(false);
  const [merchantId, setMerchantId] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalErr, setOpenModalErr] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [dataSourceName, setDataSourceName] = useState(null);
  const [method, setMethod] = useState(null);

  useEffect(() => {
    if (dashboardData.products) {
      setTransactions(dashboardData.products.transactions.is_active)
      setAuth(dashboardData.products.auth.is_active)
      setMerchantId(dashboardData.products.merchant_id.is_active)
    }
  }, [dashboardData.products])

  const toggleDataSource = useCallback(() => {
    fetch(getBaseURL(environment) + `/subscribers/products/${dataSource.product_id}`, {
      method: method,
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${webToken}`
      }),
    })
    .then(res => res.json())
    .then(data => {
      fetchSubscriberData();
      setOpenModal(false);
    })
    .catch(err => {
      setOpenModal(false);
      setOpenModalErr(true);
      console.log("Error during request", err)
    })
  }, [method, dataSource, webToken, fetchSubscriberData, environment])

  const handleToggleTransactions = useCallback(() => {
    setDataSource(dashboardData.products.transactions);
    setDataSourceName('Transactions');
    setOpenModal(true);
    !transactions ? setMethod('POST') : setMethod('DELETE');
  },[transactions, dashboardData.products])

  const handleToggleAuth = useCallback(() => {
    setDataSource(dashboardData.products.auth);
    setDataSourceName('Auth');
    setOpenModal(true);
    !auth ? setMethod('POST') : setMethod('DELETE');
  },[auth, dashboardData.products])

  const handleToggleMerchantId = useCallback(() => {
    setDataSource(dashboardData.products.merchant_id);
    setDataSourceName('Merchant ID')
    setOpenModal(true);
    !merchantId ? setMethod('POST') : setMethod('DELETE');
  },[merchantId, dashboardData.products])

  const dataTable = dashboardData.products
  ? <table>
       <thead>
         <tr>
           <th>Data Source</th>
           <StyledTh>Active</StyledTh>
         </tr>
       </thead>
       <tbody>
          <StyledTr>
            <td>Transactions</td> 
            <StyledTd>
              <Toggle 
                id={'toggle-transactions'} 
                isOn={transactions}
                handleToggle={handleToggleTransactions}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Auth</td>
            <StyledTd>
              <Toggle 
                id={'toggle-auth'}
                isOn={auth} 
                handleToggle={handleToggleAuth}
              />
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Merchant ID</td>
            <StyledTd>
              <Toggle 
                id={'toggle-merchantId'}
                isOn={merchantId} 
                handleToggle={handleToggleMerchantId}
              />
            </StyledTd>
          </StyledTr>
       </tbody>
     </table>
   : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
      <Title>Data Subscriber Dashboard</Title>
      <Row>
        <Card>
          <CardTitle><Icon><i className="fas fa-network-wired"></i></Icon>Your API's</CardTitle>
          <TableContainer>
            <Row>
              {dataTable}
            </Row>
          </TableContainer>
        </Card>
      </Row>
      <Modal
        isOpen={openModal}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Row>
            {method === 'POST'
              ? <ModalMessage>Additional fees may apply.</ModalMessage>
              : <ModalMessage>Are you sure you want to turn off {dataSourceName}?</ModalMessage>}
          </Row>
          <Row>
            <ModalButton onClick={() => setOpenModal(false)}>Cancel</ModalButton>
            <ModalButton onClick={toggleDataSource}>Accept</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={openModalErr}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <Row>
            <ModalMessage>There was a problem with your request. Please contact customer service.</ModalMessage>
          </Row>
          <Row>
            <ModalButton onClick={() => setOpenModalErr(false)}>Close</ModalButton>
          </Row>
        </ModalContent>
      </Modal>
      </Content>
    </Container>
  );
}

export default YourApis;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text };
  font-weight: 700;
  margin: 0rem 4rem 1rem 4rem;
  padding: .2rem;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  width: 50%;
  margin: 3rem 3rem;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 500;
  align-items: center;
  padding: .5rem auto;
  margin-bottom: 1rem;
`

const TableContainer = styled.div`
  margin-top: 2rem;
`

const StyledTd = styled.td`
  display: flex;
  justify-content:center;
  align-items: center;
  margin: auto;
`

const StyledTh = styled.th`
  text-align: center;
`

const StyledTr = styled.tr`
  td {
    color: ${({ theme }) => theme.colors.textSecondary }
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
  max-width: 18rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1em;
  width: 5rem;
  height: 2.25rem;
  margin: .5rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`