import styled from 'styled-components';
import React from 'react'

const PasswordValidation = ({password}) => {
    let msg = [];
    if(password.length < 8){
        msg.push("8 characters")
    }

    if(!/[A-Z]+/.exec(password)){
        msg.push("1 Capital Letter")
    }

    if(!/[a-z]+/.exec(password)){
        msg.push("1 Lower Case Letter")
    }

    if(!/[0-9]+/.exec(password)){
        msg.push("1 Number")
    }
    return msg.map((msg, idx) => { return <PWMessage key={`pw-validator-${idx}`}>*{msg} required</PWMessage>})
}

const PWMessage = styled.div`
  color: red;
`

export default PasswordValidation;