import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import isLoading from '../common/isLoading';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import moment from 'moment';

const AuthForm = ({ dashboardData, setMessage, setOpenAuth, setAlert, setQuestions, addrState, country,
    dob, setDob, questions, address, setAddress, postalCode, setPostalCode, city, setCity, submitAuthentication,
    setAddrState, setCountry, setFirstNameSt, setLastNameSt
}) => {
    
    const validateAuth = values => {
        const errors = {};
        if (!values.ssn) {
            errors.ssn = 'Last four required';
        } else if (values.ssn >= 10000) {
            errors.ssn = 'Last four'
        } else if (values.ssn < 1000) {
            errors.ssn = 'Last four'
        }
        if (!values.phone) {
            errors.phone = 'Phone number required';
        }
        if (!dob) {
            errors.dob = 'Date of birth required'
        }
        return errors;
    };
    
    const isAddressValid = useCallback((values) => {
        if (address && city && addrState && postalCode && country) {
            setFirstNameSt(values.firstName);
            setLastNameSt(values.lastName);
            submitAuthentication(values);
        } else {
            setMessage('Please select a valid address');
            setAlert({ type: 0 });
        }
    }, [address, city, addrState, postalCode, country, setMessage, submitAuthentication, setFirstNameSt, setLastNameSt, setAlert]);

    const handleAddress = useCallback(async({ value }) => {
        try {
                let response = await geocodeByPlaceId(value.place_id);
                let addr = response[0].formatted_address.split(', ');
                let stateZip = addr[2].split(' ');
                setAddress(`${addr[0]}`);
                setCity(addr[1]);
                setAddrState(stateZip[0]);
                setCountry(addr[3]);
                setPostalCode(`${stateZip[1]}`);
           }
        catch {
                console.log('Error retrieving address');
                setAlert({ type: 1 });
              }
    }, [setAlert, setAddress, setCity, setAddrState, setCountry, setPostalCode])
    
    const formik = useFormik({
        initialValues: {
            phone: '',
            ssn: '',
            firstName: dashboardData.first_name,
            lastName: dashboardData.last_name
        },
        validate: validateAuth,
        onSubmit: (values) => {
            isAddressValid(values);
        },
    });

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit}>
                <Row style={{justifyContent: 'left', alignItems: 'flex-start'}}>
                    <Column>
                        <Input 
                            style={{marginRight: '1rem', width: '6rem'}} 
                            // value={dashboardData.firstName} 
                            value={formik.values.firstName} 
                            onChange={formik.handleChange}
                            name='firstName' 
                            id='firstName'
                            placeholder='First Name'
                            // disabled
                        />
                    </Column>
                    <Column>
                        <Input 
                            style={{marginRight: '1rem', width: '6rem'}} 
                            // value={dashboardData.lastName} 
                            value={formik.values.lastName} 
                            onChange={formik.handleChange}
                            name='lastName' 
                            id='lastName'
                            placeholder='Last Name'
                            // disabled
                        />
                    </Column>
                    <Column>
                        <Row>
                            <Input 
                                style={{width: '4rem'}} 
                                value={formik.values.ssn} 
                                onChange={formik.handleChange}
                                name='ssn' 
                                id='ssn'
                                type='password' 
                                placeholder='Last 4 SSN' 
                            />
                            <SocialSpan>*</SocialSpan>
                            <SocialText>We need this information to verify your identity.</SocialText>
                        </Row>
                        {formik.errors.ssn ? <Error style={{maxWidth: '6rem'}}>{formik.errors.ssn}</Error> : <Error style={{maxWidth: '6rem'}}>&nbsp;</Error>}
                    </Column>
                </Row>
                <Row style={{marginBottom: '2rem'}}>
                    <GooglePlacesAutocomplete
                        // apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                        selectProps={{
                            onChange: handleAddress,
                            placeholder: 'Search Address..',
                            styles: {
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    fontSize: '.9em'
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    'input:focus': {
                                        boxShadow: 'none'
                                    }
                                })
                            }
                        }}
                        // renderInput={(props) => (
                        //     <Row>
                        //         <AddressInput
                        //         {...props}
                        //         autocomplete="off"
                        //         />
                        //     </Row>
                        // )}
                    />
                </Row>
                <Row style={{justifyContent: 'left', alignItems: 'flex-start'}}>
                    <Column>
                        <Input 
                            style={{width: '6rem', marginRight: '1rem'}}
                            value={formik.values.phone} 
                            onChange={formik.handleChange}
                            name='phone' 
                            id='phone'
                            type='text' 
                            placeholder='Phone Number' 
                        />
                        {formik.errors.phone ? <Error>{formik.errors.phone}</Error> : <Error>&nbsp;</Error>}
                    </Column>
                    <Column style={{alignItems: 'flex-start', height: '100%'}}>
                        <StyledDatePicker
                            selected={dob}
                            name='dob'
                            onChange={date => setDob(date)}
                            peekNextMonth
                            peekNebottomon
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="MM/dd/yy"
                            dropdownMode="select"
                            placeholderText="Date of Birth"
                            filterDate = {(date) => {
                                return moment() > date;
                            }}
                            maxDate={moment().toDate()}
                        />
                        {!dob ? <Error>{formik.errors.dob}</Error> : <Error>&nbsp;</Error>}
                    </Column>
                </Row>
                <Row>
                    <Text>
                        By clicking Submit, I permit Pentadata to obtain my credit information from Financial Institutions
                        in accordance with the Fair Credit Reporting Act (FCRA). Pentadata and its affiliates may use my
                        information to provide me with my credit score, credit report, and related consumer statements,
                        as well as information and offers regarding financial products and services that may be of interest to me.
                    </Text>
                </Row>
                <Row style={{minHeight: '3rem'}}>
                    <Button onClick={() => setOpenAuth(false)} style={{marginRight: '2rem', width: '7rem'}}>Cancel</Button>
                    <Button type='submit' style={{width: '7rem'}}>Submit</Button>
                </Row>
            </Form>
        </Container>
    )
}

export default isLoading(AuthForm);

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  max-width: 32rem;
  padding: 1rem;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 768px) {
        max-height: 28rem;
        overflow-y: auto;
    }
`

const Button = styled.button`
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.buttonPrimary };
    color: #fff;
    font-size: .9em;
    height: 2rem;
    margin-top: 1rem;
    padding: 0.2rem 0.75rem;

    :hover {
        background-color: ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : '#fff'};
        color: ${({ theme,disabled }) => disabled ? '#fff' : theme.colors.primary };
        border: 1px solid ${({ theme, disabled }) => disabled ? theme.colors.toggleOff : theme.colors.primary};
    }
`

const Input = styled.input`
    padding: .4rem .75rem;
    margin-bottom: .4rem;
    font-size: .8em;
`

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Error = styled.div`
    color: ${({ theme }) => theme.colors.error };
    font-size: .6em;
    height: 2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: .5rem;
`

const SocialText = styled.div`
    display: flex;
    font-size: .7em;
    align-self: flex-start;
    text-align: left;
    margin-left: .2rem;
    width: 8rem;
`

const SocialSpan = styled.span`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.error };
    align-self: flex-start;
    margin-left: .5rem;
    font-weight: 500;
    font-size: 1.2em;
    vertical-align: top;
`

const StyledDatePicker = styled(DatePicker)`
    height: 2rem;
    width: 6rem;
    margin-top: 0;
    margin-bottom: 0.4rem;
    font-size: .9em;
    color: ${({ theme }) => theme.colors.text };
    font-family: ${({ theme }) => theme.fonts };
`

const Text = styled.div`
    color: ${({ theme }) => theme.colors.text };
    text-align: justify;
    font-size: .8em;
    margin-bottom: 1rem;
`