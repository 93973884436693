// JWT utils for login

export const setToken = async({ token, refresh_token }) => {
  token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
  if (refresh_token) localStorage.setItem('refresh token', refresh_token);
}

export const removeToken = async() => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh token');
}

export const decipherToken = (token) => {
  return JSON.parse(atob(token.split('.')[1]));
}