import React, { useEffect, useCallback } from 'react';
import { 
  useParams 
} from 'react-router-dom';
import styled from 'styled-components';
import { FINICITY_WEBHOOK_URL } from '../services/utils';
import queryString from 'query-string';
import { BASE_URL } from '../services/utils';

const AuthProduct = () => {
  let { token, qstr } = useParams();
  const { redirectUri } = queryString.parse(qstr);
  const url = `${process.env.REACT_APP_FINICITY_BASE_URL}?${qstr}&${FINICITY_WEBHOOK_URL}`

  const fetchAuth = useCallback(() => {
    try {
      fetch(`${BASE_URL}/persons/auth/${token}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      })
      if (redirectUri) {
        window.location.replace(redirectUri);
      }
    }
    catch(e) {
      console.log('Error with auth request', e)
    }
  }, [redirectUri, token])

  const openIframe = useCallback((url) => {
    window.finicityConnect.launch(url, {
      selector: '#connect-container',
      overlay: 'rgba(59, 59, 59, 0.75)',
      success: (event) => {
        console.log('', event);
      },
      cancel: (event) => {
        console.log('', event);
      },
      error: (error) => {
        console.error('', error);
      },
      loaded: () => {
        console.log('');
      },
      route: (event) => {
        console.log('', event);
      },
      user: ({ data }) => {
        const { action } = data;
        if (action === "SubmitAccounts") {
          fetchAuth();
        }
      }
    });
  }, [fetchAuth])

  useEffect(() => {
    if (qstr) {
      openIframe(url);
    }
  }, [url, qstr, openIframe])
  
  return (
    <Container>
      <div>
        <div id='connect-container' />
      </div>
    </Container>
  );
}

export default AuthProduct;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3a;
  text-align: center;
  width: 100%;
  height: 100vh;
`